const data = [
{
    "id": 1,
    GENERIC_NAME: "HYDROCORTISONE ACETATE + LIDOCAINE",
    STRENGTH: "5 MG + 60 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 2,
    GENERIC_NAME: "SILDENAFIL CITRATE",
    STRENGTH: "50 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 4
  },
  {
    "id": 3,
    GENERIC_NAME: "BISACODYL",
    STRENGTH: "5 / 10 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 4,
    GENERIC_NAME: "ACECLOFENAC",
    STRENGTH: "100 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 5,
    GENERIC_NAME: "PARACETAMOL",
    STRENGTH: "125/250/500 MG/1 G",
    PACK_STYLE: "ALU STRIP",
    PACKING: 10
  },
  {
    "id": 6,
    GENERIC_NAME: "INDOMETACIN BP",
    STRENGTH: "50/100 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: "5,10"
  },
  {
    "id": 7,
    GENERIC_NAME: "ARTESUNATE",
    STRENGTH: "50/100/200 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 6
  },
  {
    "id": 8,
    GENERIC_NAME: "GLYCERINE USP 1/2/3/4 G",
    STRENGTH: "90% W/W",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 9,
    GENERIC_NAME: "MELOXICAM BP",
    STRENGTH: "7.5/15 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 10,
    GENERIC_NAME: "DICLOFENAC SODIUM BP",
    STRENGTH: "12.5/25/50/ 100 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 10
  },
  {
    "id": 11,
    GENERIC_NAME: "MEFANAMIC ACID BP",
    STRENGTH: "250 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 6
  },
  {
    "id": 12,
    GENERIC_NAME: "MESALAMINE USP",
    STRENGTH: "25/100/500 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 13,
    GENERIC_NAME: "DIMENHYDRINATE USP",
    STRENGTH: "25/100 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 6
  },
  {
    "id": 14,
    GENERIC_NAME: "METOCLOPRAMIDE BP",
    STRENGTH: "10MG/20MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 15,
    GENERIC_NAME: "TRAMADOL HYDROCHLORIDE BP",
    STRENGTH: "100 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  },
  {
    "id": 16,
    GENERIC_NAME: "ERGOTAMINE TARTARATE + CAFFEINE",
    STRENGTH: "2 MG + 100 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 6
  },
  {
    "id": 17,
    GENERIC_NAME: "DOMPERIDONE BP",
    STRENGTH: "10/30/60 MG",
    PACK_STYLE: "ALU STRIP",
    PACKING: 5
  }
  
  // ... Additional data can be added here ...
  
]

export default data;