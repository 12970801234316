import React from 'react';
import EyeData from './EyeData'



const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '> STRENGTH</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>PACKING</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.STRENGTH}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.PACKING}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Sachets() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>EYE/EAR DROPS</h1>
      <div className='h-[60vh] overflow-scroll'>
      <div className="App">
        <Table data={EyeData} />
      </div>
      </div>

    </div>
  )
}
