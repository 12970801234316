const data = [
  {
    id: 1,
    GENERIC_NAME: "ABACAVIR 60 MG + LAMIVUDINE 30 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 2,
    GENERIC_NAME: "ABACAVIR 600 MG + LAMIVUDINE 300 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 3,
    GENERIC_NAME: "ACAMPROSATE CALCIUM 333 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 4,
    GENERIC_NAME: "ACARBOSE 25 MG + METFORMIN 500 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 5,
    GENERIC_NAME: "ACARBOSE 25/50/100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 6,
    GENERIC_NAME: "ACECLOFENAC 100 MG + DROTAVERIN 80MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 7,
    GENERIC_NAME: "ACECLOFENAC 100MG + DIACEREIN 50 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 8,
    GENERIC_NAME: "ACECLOFENAC 100MG + PARACETAMOL 325MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 9,
    GENERIC_NAME:
      "ACECLOFENAC 100MG + PARACETAMOL 325MG + CHLORZOXAZONE 250 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 10,
    GENERIC_NAME:
      "ACECLOFENAC 100MG + PARACETAMOL 325MG + SERRATIOPEPTIDASE 10/15MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 11,
    GENERIC_NAME:
      "ACECLOFENAC 100MG + PARACETAMOL 325MG + THIOCOLCHICOSIDE 4 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 12,
    GENERIC_NAME:
      "ACECLOFENAC 100MG + PARACETAMOL-325MG + TRYPSIN CHYMOTRYPSIN-50000 / 150000 UNITS",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 13,
    GENERIC_NAME: "ACECLOFENAC 100MG + SERRATIOPEPTIDASE 15MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 14,
    GENERIC_NAME: "ACECLOFENAC 100MG + THIOCOLCHICOSIDE 4/8MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 15,
    GENERIC_NAME: "ACECLOFENAC 100MG + TIZANIDINE 2 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 16,
    GENERIC_NAME: "ACECLOFENAC SR 200MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 17,
    GENERIC_NAME: "ACETAZOLAMIDE 250 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 18,
    GENERIC_NAME: "ACICLOVIR 400 / 800 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 19,
    GENERIC_NAME: "ACOTIAMIDE 100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 20,
    GENERIC_NAME: "ACYCLOVIR 200/400/800MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 21,
    GENERIC_NAME: "ALBENDAZOLE 400MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 22,
    GENERIC_NAME: "ALBENDAZOLE 400MG + IVERMECTINE 6MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 23,
    GENERIC_NAME: "ALFUZOSIN HCL 10 MG + DUTASTERIDE 0.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 24,
    GENERIC_NAME: "ALFUZOSIN HCL 10MG ER",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 25,
    GENERIC_NAME: "ALLOPURINOL 100MG/300MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 26,
    GENERIC_NAME: "ALPHA KETOANALOUGE",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 27,
    GENERIC_NAME: "AMBRISENTAN 5 /10 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 28,
    GENERIC_NAME: "AMIODARONE 100/200 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 29,
    GENERIC_NAME: "AMISULPIRIDE 50/100/200/ 300 SR / 400 SR",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 30,
    GENERIC_NAME: "AMITRTYLINE HCL 10/25/50/75/100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 31,
    GENERIC_NAME: "AMLODINE 2.5/5/10 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 32,
    GENERIC_NAME: "AMLODINE 5MG + ATENOLOL 50MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 33,
    GENERIC_NAME: "ANASTROZOLE 1 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 34,
    GENERIC_NAME: "APIXABAN 2.5 / 5 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 35,
    GENERIC_NAME: "ARRAZOLE 5/10/15/20/30 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 36,
    GENERIC_NAME: "ARRAZOLE-5/10/15/20/30 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 37,
    GENERIC_NAME: "ATAZANAVIR 300 MG + RITONAVIR 100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 38,
    GENERIC_NAME: "ATENOLOL 25/50 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 39,
    GENERIC_NAME: "ATENOLOL 50 MG + CHLORTHALIDONE 6.25 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 40,
    GENERIC_NAME: "ATENOLOL 50 MG + HYDROCHLOROTHIAZIDE 25 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 41,
    GENERIC_NAME:
      "ATORVASTATIN 10MG + AMLODINE 5MG / CLOPIDOGREL 75MG / EZETIMIDE 10MG/FINOFIBRATE 160/145 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 42,
    GENERIC_NAME: "ATORVASTATIN 5 MG / 10 MG / 20 MG / 40 MG / 80 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 43,
    GENERIC_NAME: "AZATHIOPRINE 25/50/100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 44,
    GENERIC_NAME: "AZILSARTAN 40 /80 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 45,
    GENERIC_NAME: "AZITHROMYCIN 100 MG DT",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 46,
    GENERIC_NAME: "AZITHROMYCIN 250/500MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 47,
    GENERIC_NAME: "BACLOFEN 10 / 20 / 25 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 48,
    GENERIC_NAME: "BENIDINE HCL 4/8MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 49,
    GENERIC_NAME: "BEPOTASTINE 10 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 50,
    GENERIC_NAME: "BETAHISTINE DI HCL 4/6/8/16MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 51,
    GENERIC_NAME: "BETHANICHOL CHLORIDE 25 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 52,
    GENERIC_NAME: "BICALUTAMIDE 50 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
  {
    id: 53,
    GENERIC_NAME: "BISACODYL 5 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
  },
];

export default data;
