const data = [
    {
      "Sr no.": 1,
      GENERIC_NAME: "ADRENALINE INJ.",
      STRENGTH: "1MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 2,
      GENERIC_NAME: "ALPHA BETA ARTEETHER INJ.",
      STRENGTH: "150MG/2ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 3,
      GENERIC_NAME: "AMIKACIN SULPHATE INJ.",
      STRENGTH: "100MG / 2ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 4,
      GENERIC_NAME: "AMIKACIN SULPHATE INJ.",
      STRENGTH: "250MG / 2ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 5,
      GENERIC_NAME: "AMIKACIN SULPHATE INJ.",
      STRENGTH: "500MG / 2ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 6,
      GENERIC_NAME: "ARTEMETHER INJ",
      STRENGTH: "80MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 7,
      GENERIC_NAME: "ATACURIUM BESYLATE",
      STRENGTH: "25 MG/2.5 ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 8,
      GENERIC_NAME: "ATRACURIUM BESYLATE",
      STRENGTH: "50 MG/5 ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 9,
      GENERIC_NAME: "ATROPINE SULPHATE INJ.",
      STRENGTH: "0.6MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 10,
      GENERIC_NAME: "BETAMETHASONE SODIUM PHOSPHATE INJ.",
      STRENGTH: "4MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 11,
      GENERIC_NAME: "BUPIVACAINE DEXTROSE INJ. 4 ML",
      STRENGTH: "5MG/ML AND 80MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 12,
      GENERIC_NAME: "BUPIVACAINE INJ. 20 ML",
      STRENGTH: "5MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 13,
      GENERIC_NAME: "CHLORAMPHENICOL SODIUM SUCCINATE",
      STRENGTH: "1 GM",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 14,
      GENERIC_NAME: "CHLOROQUINE PHOSPHATE INJ. 30ML",
      STRENGTH: "40MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 15,
      GENERIC_NAME: "CHLOROQUINE PHOSPHATE INJ. 5ML",
      STRENGTH: "40MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 16,
      GENERIC_NAME: "CHLORPHENIRAMINE MALEATE INJ.",
      STRENGTH: "10MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 17,
      GENERIC_NAME: "CHLORPROMAZINE INJ",
      STRENGTH: "25MG/ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 18,
      GENERIC_NAME: "CITICHOLINE",
      STRENGTH: "250MG/ML- 2ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 19,
      GENERIC_NAME: "CITICHOLINE",
      STRENGTH: "250MG/ML- 4ML",
      TYPE: "GENERAL",
    },
    {
      "Sr no.": 20,
      GENERIC_NAME: "CLINDAMYCIN",
      STRENGTH: "300 MG",
      TYPE: "GENERAL",
    },
    // Add more entries as needed
  ];

  export default data;