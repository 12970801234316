const companyDetails = [
    { label: 'Business Type', value: 'Pharmaceutical Contract Manufacturer & Service Provider' },
    { label: 'Year of Establishment', value: '2008' },
    { label: 'Company CEO & Promoter', value: 'Mr. Manoj Aggarwal' },
    { label: 'Industry', value: 'Manufacturing an effective range of Pharmaceutical Medicines' },
    { label: 'Registered Address', value: 'Plot No: 46 First Floor, Industrial Area Phase-2, Chandigarh-160002' },
    { label: 'Legal Status of Firm', value: 'Private Ltd. Co. Registered under Indian Companies Act 1956' },
    { label: 'Warehousing Facility', value: 'Yes' },
    { label: 'Original Equipment Manufacturer', value: 'Yes' },
    { label: 'Credit Rated', value: 'Yes (FITCH)' },
    { label: 'Standard Certifications', value: 'WHO, GMP, GLP, ECA, & ISO 9001-2015 Certified' },
    { label: 'Shipment Mode', value: 'By Road' },
    { label: 'Payment Mode', value: 'DD, Cheque, Bank Transfer, Online' },
    { label: 'Location Type', value: 'Semi-Urban' },
    { label: 'Building Infrastructure', value: 'Permanent' },
    { label: 'Space Around', value: 'Front Porch' },
    { label: 'Quality Measures/Testing Facilities', value: 'Yes' },
    { label: 'Primary Competitive Advantage', value: 'Experienced R & D Department, Good Financial Position & TQM, Large Production Capacity, Large Product Line' },
  ];

export default companyDetails