const data = [
  {
    id: 1,
    GENERIC_NAME: "ACEBROPHYLINE 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 2,
    GENERIC_NAME: "AMOXYCILLIN 250 / 500 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 3,
    GENERIC_NAME: "AMOXYCILLIN 250 MG + DICLOXACILLIN 250 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 4,
    GENERIC_NAME: "AMPICILLIN & DICLOXACILLIN",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 5,
    GENERIC_NAME: "CALCIUM DOBISILATE 500MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 6,
    GENERIC_NAME: "CEPHALEXIN 250 / 500 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 7,
    GENERIC_NAME: "CINITAPRIDE 3MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 8,
    GENERIC_NAME: "CLINDAMYCIN HYDROCHLORIDE USP 300 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 9,
    GENERIC_NAME: "DEXRABEPRAZOLE 10 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 10,
    GENERIC_NAME:
      "DOXYCYCLINE HYDROCHLORIDE 100MG LACTO BACILLUS SPORES 5 BILLION",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 11,
    GENERIC_NAME: "ESOMEPRAZOLE 40MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 12,
    GENERIC_NAME: "ESOMEPRAZOLE MAGNESIUM AND DOMPERIDONE SR",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 13,
    GENERIC_NAME: "ESOMPERAZOLE 20 / 40 MG + DOMPERIDONE 30 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 14,
    GENERIC_NAME: "FLUPIRITINE MALEATE EQ. TO FLUPIRITINE 100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 15,
    GENERIC_NAME:
      "FUNGAL DIASTASE 100 MG PAPAIN 60 MG ACTIVATED CHARCOAL 75 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 16,
    GENERIC_NAME: "GABAPENTIN 300 MG METHYLCOBALAMINE JP 500 MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 17,
    GENERIC_NAME:
      "GINSENG EXT. 42.2 MG ZINC SULPHATE MONOHYDRATE 20 MG VITAMIN E (ACETATE) 10 MG THIAMINE MONONITRATE 2.0 MG PYRIDOXINE HCL 1.5 MG CYANOCOBALAMIN 1.0 MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 18,
    GENERIC_NAME: "ISOTRETINOIN 10 MG / 20 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 19,
    GENERIC_NAME: "ITRACONAZOLE 100/200 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 20,
    GENERIC_NAME:
      "LACTIC ACID BACILLUS 100 MILLION SPORES THIAMINE MONONITRATE 10MG RIBOFLAVINE 10 MG NIACINAMIDE 100 MG PYRIDOXINE HYDROCHLORIDE 3.0 MG CALCIUM PANTOTHENATE 50 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 21,
    GENERIC_NAME: "LANSOPRAZOLE 30 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 22,
    GENERIC_NAME:
      "LYCOPENE 6% 4000 MCG VITAMIN A (ACETATE) 2500 IU (POWDER FORM) VITAMIN E (ACETATE) 10 IU (CONC. POWDER FORM) VITAMIN C (COATED) 50 MG ZINC SULPHATE MONOHYDRATE 27.45MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 23,
    GENERIC_NAME:
      "METHYLCOBALAMIN 1000MCG ALPHA LOIC ACID USP 200MG PYRIDOXINE HYDROCHLORIDE 3MG FOLIC ACID 1.5MG INOSITOL 100MG BENFOTIAMINE 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 24,
    GENERIC_NAME:
      "METHYLCOBALAMIN JP 1500MCG ALPHALOIC ACID 100 MG PYRIDOXINE HYDROCHLORIDE 3.0 MG THIAMINE MONONITRATE 10 MG FOLIC ACID 1.5 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 25,
    GENERIC_NAME: "MINOCYCLIN USP 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 26,
    GENERIC_NAME: "OMEPRAZOLE 20 MG + DOMPERIDON 10 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 27,
    GENERIC_NAME: "OMEPRAZOLE 20 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 28,
    GENERIC_NAME: "PANTAPRAZOLE 40 MG + DOMPERIDONE 30 MG SR",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 29,
    GENERIC_NAME: "PANTOPRAZOLE SODIUM & SUSTAINED RELEASE LEVOSULPIRIDE",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 30,
    GENERIC_NAME:
      "PARACETAMOL 325MG DICLOFENAC SODIUM BP 50MG DICYCLOMINE HCL 10 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 31,
    GENERIC_NAME: "PARACETAMOL 325MG DICYCLOMINE HCL 20 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 32,
    GENERIC_NAME: "PREGABALIN & METHYLCOBALAMIN",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 33,
    GENERIC_NAME: "PREGABALIN 75 MG + MECOBALAMIN 750 MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 34,
    GENERIC_NAME:
      "PREGABALIN 75 MG MECOBALAMIN 750 MCG FOLIC ACID 1.5 MG PYRIDOXINE HYDROCHLORIDE 1.5 MG ALPHA LOIC ACID 100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 35,
    GENERIC_NAME: "PREGABALIN 75 MG METHYLCOBALAMINE JP 750 MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 36,
    GENERIC_NAME: "RABEPRAZOLE (GR) & LEVOSULPIRIDE (SR)",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 37,
    GENERIC_NAME: "RABEPRAZOLE 20 MG + DOMPERIDONE 30 MG SR",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 38,
    GENERIC_NAME: "RABEPRAZOLE 20 MG + ITOPRIDE 150 MG SR",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 39,
    GENERIC_NAME: "RABEPRAZOLE SODIUM 20 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 40,
    GENERIC_NAME: "RACECADOTRIL 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 41,
    GENERIC_NAME:
      "SILYMARIN 70 MG L-ORNITHINE L-ASPARTATE 150 MG N-ACETYLCYSTEINE USP 200 MG VITAMIN B1 5.0 MG VITAMIN B2 5.0 MG VITAMIN B6 3.0 MG VITAMIN B12 15 MCG CALCIUM PANTOTHENATE 10 MG NIACINAMIDE 50 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 42,
    GENERIC_NAME: "TAMSULOCIN 0.4 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 43,
    GENERIC_NAME: "THIOCOLCHICOSIDE 4 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 44,
    GENERIC_NAME: "URSODEOXYCHOLIC ACID 450MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "HARD GELATIN CAPSULES",
  },
  {
    id: 45,
    GENERIC_NAME:
      "FERROUS ASPARTO-GLYCINATE EQ. TO ELEMENTAL IRON 100MCG L-METHYLFOLATE 300MCG METHYLCOBALAMIN 500MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 46,
    GENERIC_NAME:
      "ALPHA LOIC ACID USP 100MG MECOBALAMIN 750MCG PYRIDOXINE HCL 3MG FOLIC ACID 1.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 47,
    GENERIC_NAME:
      "ALPHA LOIC ACID USP 200MG METHYLCOBALAMIN 1500MCG SELENIUM DIOXIDE MONOHYDRATED USP 55MCG CHROMIUM PICOLINATE USP 200MCG ZINC SULPHATE 27.5MG INOSITOL BP 100MG FOLIC ACID 1.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 48,
    GENERIC_NAME:
      "ALPHA LOIC ACID USP 50MG METHYLCOBALAMIN 750MCG OMEGA 3 FATTY ACID BP 500MG CHROMIUM PICOLINATE USP 200MCG SELENIUM DIOXIDE MONOHYDRATE USP 75MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 49,
    GENERIC_NAME:
      "ASTAXANTHIN 10 % 16MG REDUCED COENZYME Q 10 200MG (UBIQUINOL)",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 50,
    GENERIC_NAME:
      "BETACAROTENE 30% USP 10MG ZINC SUL. MONO. 27.5MG SELENIUM USP 70MCG MANGANESE USP 2MG COPPER BP 1MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 51,
    GENERIC_NAME:
      "BIOTIN USP 5MG MECOBALAMIN 1500MCG FOLIC ACID 5MG DOCOSAHEXANOIC ACID 200MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },

  {
    id: 52,
    GENERIC_NAME:
      "CALCITRIOL I.P 0.25 MCG CALCIUM CARBONATE I.P 500 MG (EQ. TO ELEMENTAL CALCIUM 200MG) ZINC I.P 7.5 MG (AS ZINC SULPHATE MONOHYDRATE )",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 53,
    GENERIC_NAME:
      "CALCITRIOL I.P 0.25 MCG CALCIUM CARBONATE I.P 625 MG (EQ. TO ELEMENTAL CALCIUM 250MG) VITAMIN K27 45MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 54,
    GENERIC_NAME:
      "CALCITRIOL I.P 0.25 MCG CALCIUM CITRATE MALATE I.P 500 MG ZINC I.P 7.5 MG (AS ZINC SULPHATE MONOHYDRATE )",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 55,
    GENERIC_NAME:
      "CALCITRIOL I.P 0.25 MCG CALCIUM CITRATE MALATE I.P 500 MG ZINC OXIDE EQ. ELEMENTAL ZINC 7.5MG MAGNESIUM OXIDE I.P 50 MG (EQ. TO ELEMENTAL MAGNESIUM)",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 56,
    GENERIC_NAME:
      "CALCITRIOL 0.25MCG CALCIUM CITRATE MALEATE USP 500MG VITAMIN K2-7 50MCG ZINC OXIDE 7.50MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 57,
    GENERIC_NAME:
      "CALCIUM CARBONATE ( 1250MG) EQ. TO ELEMENTAL CALCIUM 500MG CALCITRIOL 0.25MCG VITAMIN K2-7 45MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 58,
    GENERIC_NAME:
      "CALCIUM CARBONATE I.P 500 MG CALCITROL I.P 0.25MCG FISH OIL, RICH IN OMEGA 3 ACIDS BP :(CONTAINING) EICOSAPENTAENOIC ACID 180MG DOCOSAHEXAENOIC ACID 120MG METHYLCOBALAMIN 1500MCG FOLIC ACID I.P 400MCG BORON (AS DISODIUM TETRABORATE) BP 1.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 59,
    GENERIC_NAME:
      "CALCIUM CARBONATE 500MG CALCIUM CARBONATE 500MG CALCITRIOL .25MCG ZINC SULPHATE MONOHYDRATE EQ. TO ELEMENTAL ZINC 7.5MG MAGNESIUM SULPHATE EQ. TO ELEMENTAL MAGNESIUM 50MG VITAMIN K₂7 45MCG METHYLCOBALAMIN JP 1500MCG L-METHYL FOLATE 800MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 60,
    GENERIC_NAME:
      "CALCIUM CARBONATE(500MG) EQ. TO ELEMENTAL CALCIUM 200MG CALCITRIOL 0.25MCG SOYA ISOFLAVONE 40% USP 60MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 61,
    GENERIC_NAME:
      "CALCIUM CITRATE MALATE EQ. TO ELEMENTAL CALCIUM 200MG CALCITRIOL 0.25MCG METHYLCOBALAMIN JP 1500MCG FOLIC ACID 1.5MG VITAMIN B 6 3MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 62,
    GENERIC_NAME: "CHOLECALCIFEROL (VITAMIN D3) I.P 60000 I.U",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 63,
    GENERIC_NAME:
      "CLINDAMYCIN PHOSPHATE I.P EQ. TO CLINDAMYCIN 100 MG CLOTRIMAZOLE I.P 100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 64,
    GENERIC_NAME:
      "CLINDAMYCIN PHOSPHATE I.P EQ. TO CLINDAMYCIN 100 MG CLOTRIMAZOLE I.P 100 MG TINIDAZOLE 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 65,
    GENERIC_NAME:
      "CLINDAMYCIN PHOSPHATE I.P EQ. TO CLINDAMYCIN 100 MG CLOTRIMAZOLE I.P 200 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 66,
    GENERIC_NAME: "CLOFAZIMINE 100 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 67,
    GENERIC_NAME:
      "COQ10 BP 100 MG EPA 90 MG DHA 60 MG L ARGININE BP 100MG SELENIUM DIOXIDE MONO. USP 100 MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 68,
    GENERIC_NAME:
      "COQ10 BP 100MG EPA 90 MG DHA 60 MG LYCOPENE 10% USP 4000MCG SELENIUM DIOXIDE MONO. USP 70 MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 69,
    GENERIC_NAME:
      "DEXTROMETHORPHAN HBR 10MG PHENYLEPHRINE HCL 5MG CHLORPHENIRAMINE MALEATE 2MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 70,
    GENERIC_NAME: "DIHYDROARTEMISININ 40MG PERAQUINE PHOSPHATE 320MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 71,
    GENERIC_NAME: "DHENHYDRAMINE HYDROCHLORIDE 25 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 72,
    GENERIC_NAME:
      "DOCOSAHEXAENOIC ACID 100MG METHYLCOBALAMIN 750MCG FOLIC ACID 5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 73,
    GENERIC_NAME: "DUTASTERIDE 0.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 74,
    GENERIC_NAME:
      "EICOSAPENTANOIC ACID 45MG DOCOSAHEXANOIC ACID 30MG ALPHA LOIC ACID USP 100MG METHYLCOBALAMIN 500MCG CHROMIUM POLYNICOTINATE 200MCG ZINC ASCORBATE 50MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 75,
    GENERIC_NAME: "ETORICOXIB 60MG THIOCOCHICOSIDE 4MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 76,
    GENERIC_NAME:
      "EVENING PRIMROSE OIL 10% B.P 1000MG (CONTAINING GAMMA LINOLENIC ACID 100MG) NATURAL VITAMIN E B.P 150MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 77,
    GENERIC_NAME: "EVENING PRIMROSE OIL B.P 500MG COD LIVER OIL B.P 300MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 78,
    GENERIC_NAME:
      "FERROUS BISGLYCINATE 60 MG ZINC SULPHATE MONOHYDRATE EQ .TO ZINC I.P 14 MG COPPER (AS COPPER SULPHATE) 1 MG SELENIUM (AS SELENOMETHIONINE) USP 70 MCG VITAMIN B6 10 MG METHYLCOBALAMIN 1500 MCG FOLIC ACID 5 MG FISH OIL 10/40(DHA 200MG) USP 500 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 79,
    GENERIC_NAME:
      "FERROUS FUMARATE 300MG FOLIC ACID 7.5MG CYANOCOBALAMIN 7.5MCG PYRIDOXINE HCI 1.5MG ZINC AS ZINC SULPHATE 7.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 80,
    GENERIC_NAME: "FOLIC ACID 5MG DOCOSAHEXAENOIC ACID (DHA) 40% 200MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },

  {
    id: 81,
    GENERIC_NAME:
      "FOLIC ACID 5MG METHYLCOBALAMIN JP 1500MCG PYRIDOXINE HYDROCHLORIDE 1.5MG DOCOSAHEXAENOIC ACID (DHA) 40% 200MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 82,
    GENERIC_NAME:
      "GAMMA LINOLENIC ACID (FROM BORAGE OIL) 100MG METHYLCOBALAMIN 100MCG VITAMIN C 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 83,
    GENERIC_NAME:
      "GINKGO BILOBA EXTRACT 10MG GINSENG EXTRACT 25MG GRAPEFRUIT SEED EXTRACT 15MG GREEN TEA EXTRACT 10MG CHROMIUM (FROM CHROMIUM PICOLINATE) USP 75MCG ASTAXANTHIN 1.8MG BENFOTHIAMINE1.2MG BIOTIN USP 30MCG FLAXSEED OIL 100MG L - LEUCINE 18.3MG L - ISOLEUCINE 5.9MG L - VALINE 6.7MG LYCOPENE 5MG MAGNESIUM (FROM MAGNESIUM SULPHATE) BP 30MG SELENIUM (FROM SELENIUM DIOXIDE) USP 40MCG ZINC (FROM ZINC SULPHATE MONOHYDRATE) 7.5MG TAURINE 50MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 84,
    GENERIC_NAME:
      "L-METHIONINE 18.4MG L-THREONINE 4.2MG L-TRYPTOPHAN USP 5MG L-VALINE 6.7MG L-ISOLEUCINE USP 5.9MG L-LEUCINE 18.3MG L-PHENYLALANINE 5MG L-LYSINE MONO HYDROCHLORIDE USP 25MG L-HISTIDINE MONO HYDROCHLORIDE USP 3.71MG L-ARGININE 13.28MG METHYLCOBALAMIN 750MCG BIOTIN USP 0.30MCG CHOLINE CHLORIDE 150MCG BETA CAROTENE (30% DISPERSION) BP 10MG ZINC SULPHATE 15MG POTASSIUM IODIDE 0.1MG MAGNESIUM SULPHATE 7.43MG SODIUM SELENITE 10MCG CHROMIUM PICOLINATE USP 200MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 85,
    GENERIC_NAME:
      "L-METHYL FOLATE 1000MCG METHYLCOBALAMIN 1500MCG PYRIDOXAL - 5 PHOSPHATE 500MCG DHA (40%) 200MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 86,
    GENERIC_NAME:
      "L-METHYL FOLATE 1000MCG METHYLCOBALAMIN 1500MCG PYRIDOXOL-5-PHOSPHATE 500MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 87,
    GENERIC_NAME: "LEVOCETIRIZINE HCL 5MG + MONTELUKAST SODIUM 10MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 88,
    GENERIC_NAME:
      "LUTEIN 10% USP 4000 MCG LYCOPENE 10% USP 4000 MCG BETACAROTENE 30% USP 5.17 MG BLACK GRAPE SEED EXT. 10 MG SELENIUM DIOXIDE MONO. USP 25MCG ZINC SUL. MONO. 10 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 89,
    GENERIC_NAME:
      "LYCOPENE 10% 10000MCG D.L METHIONINE BP 25MG BLACK GRAPE SEED EXTRACT 10MG ADENOSYLCOBALAMIN 250MCG LUTEIN 10% 3MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 90,
    GENERIC_NAME:
      "MECOBALAMIN USP 500 MCG ALPHA LOIC ACID USP 100 MG FOLIC ACID 1.5 MG BENFOTHIAMINE 50 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 91,
    GENERIC_NAME:
      "METHYLCOBALAMIN 1500MCG FOLIC ACID I.P 1.5MG CALCIUM CARBONATE (FROM AN ORGANIC SOURCE OF OYESTER SHELL) I.P 500MG CALCITROL B.P .25MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 92,
    GENERIC_NAME:
      "METHYLCOBALAMIN 1500MCG ALFA LOIC ACID USP 100MG PYRIDOXINE HYDROCHLORIDE 1P 3MG FOLIC ACID 1.5MG BENFOTIAMINE 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },

  {
    id: 93,
    GENERIC_NAME:
      "METHYLCOBALAMIN 1500MCG ALPHA LOIC ACID USP 100MG BENFOTHIAMINE 15MG PYRIDOXINE HYDROCHLORIDE 3MG FOLIC ACID 1.5MG ZINC AS ZINC OXIDE 22.5MG CHROMIUM PICOLINATE USP 65MCG INOSITOL USP 100MG LUTEIN USP 5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 94,
    GENERIC_NAME:
      "METHYLCOBALAMIN 1500MCG ALPHA LOIC ACID USP 200 MG BENFOTIAMINE 150MG INOSITOL USP 100MG CHROMIUM POLYNICOTINATE 200MCG PYRIDOXINE HYDROCHLORIDE 3MG FOLIC ACID 1.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 95,
    GENERIC_NAME: "METHYLCOBALAMIN 750 MCG PREGABALIN 75 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 96,
    GENERIC_NAME:
      "METHYLCOBALAMIN 750MCG ALPHA LOIC ACID USP 100MG FOLIC ACID 1.5MG PYRIDOXINE HCL 3MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 97,
    GENERIC_NAME:
      "METHYLCOBALAMIN JP 1500MCG ALFA LOIC ACID USP 100MG INOSITOL 100MG CHROMIUM POLYNICOTINATE 200MCG FOLIC ACID 1.5MG SELENIUM DIOXIDE 55MCG BENFOTIAMINE 150MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 98,
    GENERIC_NAME:
      "METHYLCOBALMIN I.P 1500 MCG ALPHA LOIC ACID USP 100MG FOLIC ACID I.P 1.5MG PYRIDOXINE HCL I.P 3MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 99,
    GENERIC_NAME: "NATURAL MICRONISED PROGESTERONE BP 100MG/200MG/300MG/400MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 100,
    GENERIC_NAME:
      "OMEGA 3 MARINE TRIGLYCERIDES PROVDING USP EICOSAPENTANOIC ACID 90MG DOCOSAHEXANOIC ACID 60MG FOLIC ACID 5MG SODIUM SELENITE PENTAHYDRATE 40MCG METHYLCOBALAMIN 500MCG COPPER SULPHATE PENTAHYDRATE 1MG ZINC SULPHATE 27.45MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 101,
    GENERIC_NAME:
      "OMEGA 3 MERINE TRIGLYCERIDES USP 500MG ALPHA LOIC ACID USP 50MG VITAMIN B12 15MCG CHROMIUM PICOLINATE USP 200MCG SELENIUM DIOXIDE MONOHYDRATE USP 75MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 102,
    GENERIC_NAME:
      "REFINED BORAGE OIL PROVIDING LINOLENIC ACID 60MG ALPHA LOIC ACID USP 100MG METHYLCOBALAMIN 1500MCG PYRIDOXINE HYDROCHLORIDE 5MG CHROMIUM PICOLINATE EQ. TO CHROMIUM USP 50MCG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },

  {
    id: 103,
    GENERIC_NAME: "SILDENAFIL CITRATE 100MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 104,
    GENERIC_NAME: "SIMETHICONE USP 140 MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 105,
    GENERIC_NAME:
      "VITAMIN A USP (AS PALMITATE) 2000 IU VITAMIN B1 BP 1.0 MG. VITAMIN B2 BP 1.0 MG. VITAMIN B6 BP 1.34 MG. BIOTIN USP 25 MCG. CALCIUM PANTOTHENATE USP 1.0 MG. VITAMIN B12 BP 1.0 MG. VITAMIN E ACETATE USP 10 MG. VITAMIN D3 USP 200 IU CALCIUM ( FROM DICALCIUM PHOSPHATE BP) 190 MG. PHOSPHORUS (FROM DICALCIUM PHOSPHATE BP) 140 MG. IRON (FROM FERROUS SULPHATE BP) 5 MG. MOLYBDENUM (FROM SODIUM MOLUBDATE DIHYDRATE BP) 0.1 MG. SELENIUM (FROM SELENIOUS ACID USP) 100 MCG. MAGNESIUM (FROM MAGNESIUM SULPHATE BP) 1 MG. MANGANESE (FROM MANGANESE SULPHATE BP) 0.01 MG. CHROMIUM (FROM CHROMIUM TRICHLORIDE ANHYDROUS USP) 27 MCG. ZINC (FROM ZINC SULPHATE BP) 0.15 MG.",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  {
    id: 106,
    GENERIC_NAME:
      "VITAMIN D3 1000IU METHYLCOBALAMIN 1500MCG PYRIDOXINE HCI 3MG FOLIC ACID 1.5MG",
    PACK_STYLE: "BLISTER/STRIP/BOTTLES",
    CATEGORY: "SOFT GEL CAPSULES",
  },
  // ... Additional data can be added here ...
];

export default data;
