import React from 'react'

const data = [
  { id: 1, GENERIC_NAME: "ADERNALINE", STRENGTH: "1ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 2, GENERIC_NAME: "ATROPINE", STRENGTH: "1ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 3, GENERIC_NAME: "DALTAPARIN", STRENGTH: "2500IU 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 4, GENERIC_NAME: "DALTAPARIN", STRENGTH: "5000IU 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 5, GENERIC_NAME: "DICLOFENAC", STRENGTH: "1ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 6, GENERIC_NAME: "ENOXAPARIN SODIUM", STRENGTH: "20MG (PFS) 0.2ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 7, GENERIC_NAME: "ENOXAPARIN SODIUM", STRENGTH: "40MG (PFS) 0.4ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 8, GENERIC_NAME: "ENOXAPARIN SODIUM", STRENGTH: "60MG (PFS) 0.6ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 9, GENERIC_NAME: "ENOXAPARIN SODIUM", STRENGTH: "80MG (PFS) 0.8ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 10, GENERIC_NAME: "ERYTHROPOIETIN", STRENGTH: "2000IU 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 11, GENERIC_NAME: "ERYTHROPOIETIN", STRENGTH: "4000IU 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 12, GENERIC_NAME: "ERYTHROPOIETIN", STRENGTH: "10000IU 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 13, GENERIC_NAME: "ERYTHROPOIETIN", STRENGTH: "40000IU 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 14, GENERIC_NAME: "FILGRASTIM", STRENGTH: "300 MCG 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 15, GENERIC_NAME: "FULVESTRANT", STRENGTH: "250 MG 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 16, GENERIC_NAME: "FULVESTRANT", STRENGTH: "500 MG 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 17, GENERIC_NAME: "METHOTREXATE", STRENGTH: "1ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 18, GENERIC_NAME: "NEOSTIGMINE", STRENGTH: "1ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 19, GENERIC_NAME: "ONDANSETRON", STRENGTH: "1ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 20, GENERIC_NAME: "OXYTOCIN", STRENGTH: "1ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 21, GENERIC_NAME: "SODIUM HYALURONATE", STRENGTH: "0.55 ML 4 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 22, GENERIC_NAME: "SODIUM HYALURONATE", STRENGTH: "15 MG/1ML 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 23, GENERIC_NAME: "SODIUM HYALURONATE", STRENGTH: "20 MG/2ML 4 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 24, GENERIC_NAME: "SODIUM HYALURONATE", STRENGTH: "10 MG/ 0.4ML PFS 4 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 25, GENERIC_NAME: "SODIUM HYALURONATE", STRENGTH: "15 MG/2ML 4 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
  { id: 26, GENERIC_NAME: "VASOPRESSIN", STRENGTH: "20 IU 1 ML", PACK_STYLE: "PFS", PACKING: "1ML" },
];





const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>STRENGTH</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>PACKING</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.STRENGTH}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.PACKING}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Sachets() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>PREFILLED SYRINGES (PFS)</h1>
      <div className="App">
        <Table data={data} />
      </div>

    </div>
  )
}
