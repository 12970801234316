const data = [
    { id: 1, GENERIC_NAME: "NEBULIZER MACHINE", PACKING: "1 PCS" },
    { id: 2, GENERIC_NAME: "MESH NEBULIZER", PACKING: "1 PCS" },
    { id: 3, GENERIC_NAME: "PULSE OXIMETER", PACKING: "BOX OF 10 PCS" },
    { id: 4, GENERIC_NAME: "DIGITAL THERMOMETER", PACKING: "BOX OF 10 PCS" },
    { id: 5, GENERIC_NAME: "AIR BED", PACKING: "1 PCS" },
    { id: 6, GENERIC_NAME: "BP MONITOR", PACKING: "1 PCS" },
    { id: 7, GENERIC_NAME: "VAPORIZER", PACKING: "1 PCS" },
    { id: 8, GENERIC_NAME: "FETAL DOPPLER", PACKING: "1 PCS" },
    { id: 9, GENERIC_NAME: "SUCTION MACHINE", PACKING: "BOX OF 1 PCS" },
    { id: 10, GENERIC_NAME: "BODY MASSAGER", PACKING: "BOX OF 1 PCS" },
    { id: 11, GENERIC_NAME: "BREAST PUMP : MANUAL", PACKING: "BOX OF 1 PCS" },
    { id: 12, GENERIC_NAME: "NEEDLE SYRINGE DESTROYER – PLASTIC", PACKING: "1 PCS" },
    { id: 13, GENERIC_NAME: "NEEDLE SYRINGE DESTROYER – MANUAL", PACKING: "1 PCS" },
    { id: 14, GENERIC_NAME: "NEEDLE SYRINGE DESTROYER – METAL", PACKING: "1 PCS" },
    { id: 15, GENERIC_NAME: "STATURE METER", PACKING: "-" },
    { id: 16, GENERIC_NAME: "SPIROMETER", PACKING: "-" },
    { id: 17, GENERIC_NAME: "CONTROL PENCIL", PACKING: "PKT OF 10 PCS" },
    { id: 18, GENERIC_NAME: "INFUSION PUMP BYS-820", PACKING: "1PC" },
    { id: 19, GENERIC_NAME: "CHANNEL SYRINGE PUMP BYZ-810D", PACKING: "1PC" },
    { id: 20, GENERIC_NAME: "OXYPLUS 240ST TABLE TOP PULSE OXIMETER", PACKING: "1PC" },
    { id: 21, GENERIC_NAME: "SPO2 SENSOR", PACKING: "1PC" },
    { id: 22, GENERIC_NAME: "DIGITAL 3 CHANNEL ECG MACHINE", PACKING: "1PC" },
    { id: 23, GENERIC_NAME: "CONTACT INFRARED FOREHEAD THERMOMETER FOR HOSPITAL", PACKING: "1PC" },
    { id: 24, GENERIC_NAME: "12 CHANNEL ECG MACHINE WITH GLASGOW ALGORITHM", PACKING: "1PC" },
    { id: 25, GENERIC_NAME: "PORTABLE LIGHT-WEIGHT FETAL MONITOR WITH COLOR DISPLAY", PACKING: "1PC" },
    { id: 26, GENERIC_NAME: "SINGLE HOSE CUFF ADULT 25CM TO 35 CM GREY", PACKING: "1PC" },
    { id: 27, GENERIC_NAME: "LUMIS 150 VPAP ST", PACKING: "1PC" },
    { id: 28, GENERIC_NAME: "BMC RESMART GII AUTO BIPAP WITH HUMIDIFIER BREATHING RESPIRATORY EXERCISER", PACKING: "1PC" },
    { id: 29, GENERIC_NAME: "BPC METALLIC FLOW METER WITH HUMIDIFIER BOTTLE", PACKING: "1PC" },
];


export default data;