
import React from "react";

import ProductMain from '../../../../elements/ProductMain';

const medicines = [
  {
    subtitle:"Tablets",
    subproducts:[
      {
        name:"Acelofenac & Paracetamol Tablets",
        image: require("../../../../assets/eternal/tablet1.jpg")  
      },
      {
        name:"Abacavir 60 MG + Lamivudine 30 MG Tablets",
        image: require("../../../../assets/eternal/tablet2.jpg")  
      },
      {
        name:"Acetazolamide 250 MG Tablets",
        image: require("../../../../assets/eternal/tablet3.jpg")  
      },
      {
        name:"Bosentan 62.5 MG Tablets",
        image: require("../../../../assets/eternal/tablet4.jpg")  
      },
    ]
  },
  {
    subtitle:"Capsules",
    subproducts:[
      {
        name:"Acelofenac & Paracetamol Tablets",
        image: require("../../../../assets/eternal/tablet1.jpg")  
      },
      {
        name:"Abacavir 60 MG + Lamivudine 30 MG Tablets",
        image: require("../../../../assets/eternal/tablet2.jpg")  
      },
      {
        name:"Acetazolamide 250 MG Tablets",
        image: require("../../../../assets/eternal/tablet3.jpg")  
      },
      {
        name:"Bosentan 62.5 MG Tablets",
        image: require("../../../../assets/eternal/tablet4.jpg")  
      },
    ]
  }
]

export default function EnterFormulations() {
  return (
    <div>
      <h1 className="bg-green-200 p-[1vh] px-[3vh] text-[2.5vh] lg:text-[3vh]">
      TopicalFormulations
      </h1>
      {medicines.map((medicine,index)=>(
        <ProductMain 
          key={index}
          subtitle={medicine.subtitle}
          subcategories={medicine.subproducts}
        />
      ))}  
    </div>
  );
}

