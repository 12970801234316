import React from "react";
import "./OurExpertise.css";
import infra from "../../assets/infrastructure.mp4";
import research from '../../assets/r&d.webp'
import companyDetails from "./CompanyDetails";

export default function OurExpertise() {
  return (
    <div className="mt-[2vh]">

      <div className="relative">
        <video className="mx-auto w-[90vw]" loop autoPlay muted>
          <source src={infra} type="video/mp4" />
        </video>
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
          <h1 className="text-blue-800 text-[2vh] md:text-[3vh] lg:text-[6vh] font-bold">Own Premises</h1>
          <p className="text-gray-700 text-[1vh] text-justify w-[80%] md:text-[2vh]  lg:text-[3vh] ">
            Our headquarters are located in Chandigarh and are fully equipped
            with cutting-edge technological systems. Our entire infrastructure
            has been thoughtfully segmented into distinct sections, including
            R&D, manufacturing, quality control, and warehousing and packaging.
            Each team is led by a senior professional who is an expert in their
            field. The entire facility is expansive and equipped with all of the
            necessary safety and purity controls for pharmaceutical
            formulations.
          </p>
        </div>
      </div>

      <div className="flex flex-col-reverse w-[100vw] my-[10vh] shadow-lg border-[0.1vh] mx-auto md:flex-row md:w-[90vw] lg:flex-row">
        <div className="text-center p-[2vh] ">
          <h1 className="text-[6vh]  text-[#001F8E] mx-auto">Research & Development</h1>
          <p className="text-[2.5vh] p-[1vh] text-justify ">Our Chandigarh headquarters are fully equipped with cutting-edge technological systems. Our entire infrastructure has been carefully segmented into distinct areas, including research and development, manufacturing, quality control, and warehousing and packaging. Each team is led by a senior professional who is an authority in their respective fields. The entire facility is large and equipped with all of the necessary safety and purity controls for pharmaceutical formulations.</p>
        </div>

        <img src={research} className="w-[100%] md:w-[40%] lg:w-[60%]" alt="research" />
      </div>




      <div >
        <h1 className="text-[6vh] text-center  text-[#001F8E] mx-auto">Overview of Pelikas Healthcare</h1>
        <table className="mx-auto w-[100vw] md:w-[50vw] lg:w-[60vw] my-[5vh]">
          <tbody>
            {companyDetails.map((detail, index) => (
              <tr key={index}>
                <td className="font-medium text-[2.3vh] p-[2vh] border-[0.2vh] border-blue-950">{detail.label} :</td>
                <td className="text-[2.2vh] p-[2vh] border-[0.2vh] border-blue-950">{detail.value}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
}
