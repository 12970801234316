
import fedex from '../../assets/fedex.jpg'
import aramex from '../../assets/aramex.jpg'
import bluedart from '../../assets/bluedart.jpg'
import emirates from '../../assets/emirates.jpg'
import air from '../../assets/air.jpg'
import copa from '../../assets/copa.jpg'
import ethiopian from '../../assets/ethiopian.jpg'
import dubai from '../../assets/dubai.jpg'
import qatar from '../../assets/qatar.jpg'
import united from '../../assets/united.jpg'
import turkish from '../../assets/turkish.jpg'
import luft from '../../assets/luft.jpg'
import singapore from '../../assets/singapore.jpg'
import etihad from '../../assets/etihad.jpg'
import american from '../../assets/american.jpg'
import ecom from '../../assets/ecom.jpg'
import dhcl from '../../assets/dhcl.jpg'
import ems from '../../assets/ems.jpg'
import hapag from '../../assets/hapag.jpg'
import maersk from '../../assets/maersk.jpg'
import matson from '../../assets/matson.jpg'
import oocl from '../../assets/oocl.jpg'

const airCargo = [
    {image : emirates},
    {image : aramex},
    {image : air},
    {image : copa},
    {image : ethiopian},
    {image : dubai},
    {image : qatar},
    {image : united},
    {image : turkish},
    {image : luft},
    {image : singapore},
    {image : etihad},
    {image : american},
]

const seaCargo = [
    {image : fedex},
    {image : aramex},
    {image : air},
    {image : ecom},
    {image : bluedart},
    {image : dhcl},
    {image : ems},
    {image : hapag},
    {image : maersk},
    {image : matson},
    {image : oocl},
    
]

export  { airCargo, seaCargo };
