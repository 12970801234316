

import React from 'react'

const data = [
  { id: 1, GENERIC_NAME: "MUPIROSIN OINTMENT", STRENGTH: "2% W/W", TYPE: "OINTMENT" },
  { id: 2, GENERIC_NAME: "POVIDONE- IODINE OINTMENT", STRENGTH: "5%/10% W/W", TYPE: "OINTMENT" },
  { id: 3, GENERIC_NAME: "TACROLIMUS OINTMENT", STRENGTH: "0.1% / 0.03% W/W", TYPE: "OINTMENT" },
  { id: 4, GENERIC_NAME: "ADAPALENE & CLINDAMYCIN PHOSPHATE GEL", STRENGTH: "1 MG + 10 MG", TYPE: "GELS" },
  { id: 5, GENERIC_NAME: "ADAPALENE GEL", STRENGTH: "0.1% W/W", TYPE: "GELS" },
  { id: 6, GENERIC_NAME: "AZITHROMYCIN GEL", STRENGTH: "2%W/W", TYPE: "GELS" },
  { id: 7, GENERIC_NAME: "CLOTRIMAZOLE GEL", STRENGTH: "2% W/W", TYPE: "GELS" },
  { id: 8, GENERIC_NAME: "DICLOFENAC SODIUM, METHYL SALICYLATE, MENTHOL, LINSEED OIL & BENZYL ALCOHOL GEL", STRENGTH: "1.16% W/W + 10.0% W/W + 5.0% W/W + 3.0% W/W + 1.0% W/W", TYPE: "GELS" },
  { id: 9, GENERIC_NAME: "ITRACONAZOLE GEL", STRENGTH: "1% W/W", TYPE: "GELS" },
  { id: 10, GENERIC_NAME: "KETOPROFEN GEL", STRENGTH: "2.5% W/W", TYPE: "GELS" },
  { id: 11, GENERIC_NAME: "SILVER NITRATE GEL", STRENGTH: "0.2%W/W", TYPE: "GELS" },
  { id: 12, GENERIC_NAME: "AMOROLFINE HYDROCHLORIDE CREAM", STRENGTH: "0.25% W/W", TYPE: "CREAM" },
  { id: 13, GENERIC_NAME: "BIFONAZOLE & BENZYL ALCOHOL CREAM", STRENGTH: "1% W/W + 2% W/W", TYPE: "CREAM" },
  { id: 14, GENERIC_NAME: "CLOBETASOL & SALICYLIC CREAM", STRENGTH: "0.05% W/W + 3.0% W/W", TYPE: "CREAM" },
  { id: 15, GENERIC_NAME: "CLOBETASOL PROPIONATE & MICONAZOLE NITRATE CREAM", STRENGTH: "0.05% W/W + 2.0% W/W", TYPE: "CREAM" },
  { id: 16, GENERIC_NAME: "CLOBETASOL PROPIONATE CREAM", STRENGTH: "0.05 % W/W", TYPE: "CREAM" },
  { id: 17, GENERIC_NAME: "CLOTRIMAZOLE & BECLOMETHASONE CREAM", STRENGTH: "1% W/W +0.025% W/W", TYPE: "CREAM" },
  { id: 18, GENERIC_NAME: "CLOTRIMAZOLE BECLOMETHASOLNE & GENTAMICIN CREAM", STRENGTH: "1% W/W + 0.025% W/W + 0.1% W/W", TYPE: "CREAM" },
  { id: 19, GENERIC_NAME: "CLOTRIMAZOLE CREAM", STRENGTH: "1% W/W", TYPE: "CREAM" },
  { id: 20, GENERIC_NAME: "CLOTRIMAZOLE, BECLOMETHASONE DIPROPIONATE & NEOMYCIN SULPHATE CREAM", STRENGTH: "1.0% W/W + 0.025% W/W + 0.5% W/W", TYPE: "CREAM" },
  { id: 21, GENERIC_NAME: "CLOTRIMAZOLE, CLOBETASOL & GENTAMICIN CREAM", STRENGTH: "1%W/W +0.05% W/W +0.1% W/W", TYPE: "CREAM" },
  { id: 22, GENERIC_NAME: "LULICONAZOLE CREAM", STRENGTH: "1% W/W", TYPE: "CREAM" },
  { id: 23, GENERIC_NAME: "MICONAZOLE NITRATE, NEOMYCIN SULPHATE AND CLOBETASOL PROPIONATE CREAM", STRENGTH: "2.0%W/W + 0.5% W/W + 0.05% W/W", TYPE: "CREAM" },
  { id: 24, GENERIC_NAME: "SERTOCONAZOLE & NITRATE CREAM", STRENGTH: "2% W/W", TYPE: "CREAM" },
  { id: 25, GENERIC_NAME: "SILVER SULFADIAZINE CREAM", STRENGTH: "1% W/W", TYPE: "CREAM" },
  { id: 26, GENERIC_NAME: "TERBINAFINE HYDROCHLORIDE CREAM", STRENGTH: "1.0% W/W", TYPE: "CREAM" },
];




const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>STRENGTH</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>TYPE</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.STRENGTH}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.TYPE}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Sachets() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>CREAM/OINTMENTS/GELS</h1>
      <div className='h-[60vh] overflow-scroll'>
        <div className="App">
          <Table data={data} />
        </div>
      </div>

    </div>
  )
}


