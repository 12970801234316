import React, { useEffect, useRef } from "react";
import swiper1 from "../../assets/swiper1.jpg";
import swiper2 from "../../assets/swiper2.webp";
import swiper3 from "../../assets/swiper3.jpg";
import swiper4 from "../../assets/swiper4.jpg";
import swiper5 from "../../assets/swiper5.jpg";
import swiper6 from "../../assets/swiper6.jpg";
import swiper7 from "../../assets/swiper7.jpg";
import swiper8 from "../../assets/swiper8.jpg";
// import caption from "../../assets/caption.jpg";
import doctor from "../../assets/doctorlady.webp";
import exp1 from "../../assets/expertise1.jpg";
import exp2 from "../../assets/expertise2.jpg";
import exp3 from "../../assets/expertise3.jpg";
import exp4 from "../../assets/expertise4.jpg";
import icon1 from "../../assets/suitcase.jpg";
import icon2 from "../../assets/time.jpg";
import icon3 from "../../assets/delivery.jpg";
import icon4 from "../../assets/certified.jpg";

import whychooseus from "../../assets/whychooseus.png";

import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./Home.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import ReloadCounter from "../../elements/ReloadCounter";
import ReloadCounter2 from "../../elements/ReloadCounter2";
import ReloadCounter3 from "../../elements/ReloadCounter3";
// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

export default function Home() {
  const swiperRef = useRef(null);
  const totalSlides = 9; // The total number of slides
  const slideInterval = 1500; // 1.5 seconds in milliseconds
  const currentSlideRef = useRef(0); // Use useRef to store the current slide

  useEffect(() => {
    const swiperInstance = swiperRef.current;

    const autoplay = setInterval(() => {
      currentSlideRef.current = (currentSlideRef.current + 1) % totalSlides;
      if (swiperInstance && swiperInstance.slideTo) {
        swiperInstance.slideTo(currentSlideRef.current);
      }
    }, slideInterval);

    return () => {
      clearInterval(autoplay);
    };
  }, []);

  return (
    <div className="w-[90%] md:w-[85%] mx-auto">
      {/* Main Home Section */}
      <div className="flex flex-col-reverse justify-center items-center md:flex-row lg:flex-row">
        <div className="flex flex-col">
          <h1 className="gradient-text text-[4vh] md:text-[6vh] lg:text-[7vh] xl:text-[8.5vh]">
            Guiding you to a Healthier Tommorrow
          </h1>

          <p className="text-[2.3vh] md:text-[2.5vh] lg:text-[3vh] text-gray-600 my-[2vh]">
            Pelikas Healthcare Pvt. Ltd., a trailblazing pharmaceutical company
            with over years of manufacturing and marketing expertise.
          </p>

          <Link to="/about">
            <button
              className="button text-left text-white w-fit px-[2vh] py-[1vh] text-[2.3vh] md:text-[2.7vh]"
            >
              View Products
            </button>
          </Link>
        </div>
        <img
          src={doctor}
          className=" w-[100vw] md:w-[35vw] lg:w-[35vw] my-[1vh]"
          alt="heading"
        />
      </div>

      {/* absolute Section */}
      <div className="circle1 w-[10vh] h-[10vh] md:w-[60vh] md:h-[60vh]  bg-red-500 rounded-full absolute -z-10 -right-[10%] top-[160%]"></div>
      <div className="circle2 w-[10vh] h-[10vh] md:w-[60vh] md:h-[60vh]  bg-red-500 rounded-full absolute -z-20 -left-[10%] top-[90%]"></div>
      <div className="circle2 w-[10vh] h-[10vh] md:w-[60vh] md:h-[60vh]  bg-red-500 rounded-full absolute -z-10 -right-[10%] top-[310%]"></div>
      <div className="circle1 w-[10vh] h-[10vh] md:w-[60vh] md:h-[60vh]  bg-red-500 rounded-full absolute -z-20 -left-[10%] top-[230%]"></div>

      {/* About Section */}
      <div className="flex flex-col justify-center items-center mt-[5vh] mb-[10vh]">
        <h1 className="text-[4vh] md:text-[6vh] text-[#424242] font-semibold">
          About Us
        </h1>
        <p className="text-[#4d4d4d] text-center mx-auto my-[2vh] text-[2.3vh] md:text-[2.6vh] ">
          Pelikas Healthcare Pvt. Ltd., a trailblazing pharmaceutical company
          with over years of manufacturing and marketing expertise. From our
          Mumbai-based head office in Maharashtra, India, we are revolutionizing
          the industry by delivering products that surpass international
          standards of excellence. As proud bearers of ISO and GDP compliance,
          we leave no stone unturned in ensuring the highest levels of safety,
          quality, and efficacy for our valued customers.
        </p>
        <div className="flex flex-row flex-wrap justify-center mx-auto gap-[2.5vh]">
          <ReloadCounter
            finalCount={100}
            title="Client served with excellence"
            gradient="from-[#407a8c85] to-[#d9d9d989]"
          />
          <ReloadCounter
            finalCount={150}
            title="Client served with excellence"
            gradient="from-[#4d713971] to-[#d9d9d989]"
          />
          <ReloadCounter2
            finalCount={5000}
            title="Client served with excellence"
            gradient="from-[#407a8c85] to-[#d9d9d989]"
          />
          <ReloadCounter3
            finalCount={25000}
            title="Client served with excellence"
            gradient="from-[#4d713971] to-[#d9d9d989]"
          />
        </div>
        <Link to="/products">
          <button
            className="button text-white my-[2vh] w-fit px-[2vh] py-[1vh] text-[2.3vh] md:text-[2.7vh]"
          >
            Know More
          </button>
        </Link>
      </div>

      {/* Main Slider Images */}
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"1"}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        coverflowEffect={{
          rotate: 60,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: true,
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          }
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper mb-[10vh]"
      >
        <SwiperSlide>
          <img src={swiper1} alt="slider1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper2} alt="slider2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper3} alt="slider3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper4} alt="slider4" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper5} alt="slider5" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper6} alt="slider6" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper7} alt="slider7" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiper8} alt="slider8" />
        </SwiperSlide>
      </Swiper>

      {/* Our Expertise*/}
      <div className="mb-[5vh]">
        <h1 className="text-center text-[4vh] md:text-[6vh] text-[#424242] font-semibold">
          Our Expertise
        </h1>

        <div className="flex flex-col flex-wrap justify-center items-center md:flex-row lg:flex-row">
          <div className="flex flex-col justify-center shadow-md h-auto m-[3vh] md:flex-row lg:flex-row">
            <img
              src={exp1}
              className="w-[full] md:w-[16vw] h-auto"
              alt="medicine"
            />
            <div className="px-[2vh] bg-gradient-to-r from-[#fafdfe] to-[#c8edfb]">
              <div className="text-[#4d4d4d] flex flex-col text-center p-[2vh] w-[70vw]  md:w-[18vw] md:p-[1vh]">
                <h1 className="text-[3vh] font-semibold my-[1.5vh] ">
                  PHARMACEUTICALS
                </h1>
                <p className="text-justify">
                  Well equipped with 15 state-of-the-art manufacturing
                  facilities to produce pharmaceutical formulations in almost
                  all dosage forms & therapeutic segments to cater to medicinal
                  requirements across the globe..
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center shadow-md h-auto m-[3vh] md:flex-row lg:flex-row">
            <img
              src={exp2}
              className="w-[full] md:w-[16vw] h-auto"
              alt="medicine"
            />
            <div className="px-[2vh] bg-gradient-to-r from-[#fafff7] to-[#dfffcc]">
              <div className="text-[#4d4d4d] flex flex-col text-center p-[2vh] w-[70vw]  md:w-[18vw] h-auto md:p-[1vh]">
                <h1 className="text-[3vh] font-semibold my-[1.5vh]">
                  NUTRACEUTICALS
                </h1>
                <p className="text-justify ">
                  Provide High-quality dietary supplements to a broad spectrum
                  of market segments, spanning from chronic to preventative care
                  by leveraging the power of natural solutions & utilizing
                  globally-recognized technology..
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center shadow-md h-auto m-[3vh] md:flex-row lg:flex-row">
            <img
              src={exp3}
              className="w-[full] md:w-[16vw] h-auto"
              alt="medicine"
            />
            <div className="px-[2vh] bg-gradient-to-r from-[#fafff7] to-[#dfffcc]">
              <div className="text-[#4d4d4d] flex flex-col text-center p-[2vh] w-[70vw] h-auto md:w-[18vw] md:p-[1vh]">
                <h1 className="text-[3vh] font-semibold my-[1.5vh]">API</h1>
                <p className="text-justify ">
                  Well equipped with 15 state-of-the-art manufacturing
                  facilities to produce pharmaceutical formulations in almost
                  all dosage forms & therapeutic segments to cater to medicinal
                  requirements across the globe..
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center shadow-md h-auto m-[3vh] md:flex-row lg:flex-row">
            <img
              src={exp4}
              className="w-[full] md:w-[16vw] h-auto"
              alt="medicine"
            />
            <div className="px-[2vh] bg-gradient-to-r from-[#fafdfe] to-[#c8edfb]">
              <div className="text-[#4d4d4d] flex flex-col text-center p-[2vh] w-[70vw] h-auto md:w-[18vw] md:p-[1vh]">
                <h1 className="text-[3vh] font-semibold my-[1.5vh]">
                  COSMETICS & DERMATOLOGY
                </h1>
                <p className="text-justify ">
                  Well equipped with 15 state-of-the-art manufacturing
                  facilities to produce pharmaceutical formulations in almost
                  all dosage forms & therapeutic segments to cater to medicinal
                  requirements across the globe..
                </p>
              </div>
            </div>
          </div>

          <Link to="/products">
            <button
              className="button text-white my-[2vh] w-fit px-[2vh] py-[1vh] text-[2.3vh] md:text-[2.7vh]"
            >
              Know More
            </button>
          </Link>
        </div>
      </div>

      {/* Why Choose Us.. */}
      <div className="my-[10vh]">
        <h1 className="text-center text-[4vh] md:text-[6vh] text-[#424242] font-semibold">
          Why Choose Us..?
        </h1>
        <p className="text-center text-[#4d4d4d]  m-[4vh] text-[2.6vh] ">
          As proud bearers of ISO and GDP compliance, we leave no stone unturned
          in ensuring the highest levels of safety, quality, and efficacy for
          our valued customers. Our state-of-the-art manufacturing units boast
          prestigious certifications such as WHO-GMP and GLP, reflecting our
          unwavering commitment to global best practices.
        </p>

        <div className="w-[85vw] mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="flex mt-[2vh] flex-col gap-y-[2vh] w-[100%]  mx-auto md:w-[45%] lg:w-[30%]">

            <div className="bg-[#9CE7FF33] w-[auto] h-[35vh] justify-evenly flex flex-col p-[3vh] shadow-xl">
              <div className="float">
                <h1 className="text-[#001f8ed6] text-[3.4vh] md:text-[4vh] float-left font-semibold w-[80%] ">
                  500+
                </h1>
                <img
                  src={icon1}
                  className="float-right w-[14%] md:w-[17%] lg:w-[20%]"
                  alt="suitcase"
                />
                <h1 className="text-[#001f8ed6] text-[3vh] md:text-[3.5vh]  float-left font-semibold w-[80%] ">
                  products range.
                </h1>

              </div>
              <p className="mt-[2.5vh] text-[#5b5b5b] text-[2.7vh] md:text-[2.6vh] lg:text-[3vh]">
                Pharma franchise products underdifferent divisions
              </p>
            </div>

            <div className="bg-[#C0FE9A33] h-[35vh] justify-evenly flex flex-col p-[3vh] shadow-xl">
              <div className="float">
                <h1 className="text-[#001f8ed6] text-[3.4vh] md:text-[4vh] float-left font-semibold w-[80%] ">
                  Timely
                </h1>
                <img
                  src={icon2}
                  className="float-right w-[14%] md:w-[17%] lg:w-[20%]"
                  alt="suitcase"
                />
                <h1 className="text-[#001f8ed6] text-[3vh] md:text-[3.5vh] float-left font-semibold w-[80%]  ">
                  delivery of goods
                </h1>

              </div>
              <p className="mt-[2.5vh] text-[#5b5b5b] text-[2.7vh] md:text-[2.6vh] lg:text-[3vh]">
                Timely supply of medicine is essential in franchise business
              </p>
            </div>
          </div>

          <img
            src={whychooseus}
            alt="whychooseus"
            className="whychooseus w-[40vh] h-[60vh] mb-[2vh] rounded-xl shadow-lg"
          />

          <div className="flex mt-[2vh] flex-col justify-evenly gap-y-[2vh] w-[100%] mx-auto md:w-[45%] lg:w-[30%]">
            <div className="bg-[#C0FE9A33] flex flex-col h-[35vh] justify-evenly p-[3vh] shadow-xl">
              <div className="float">
                <h1 className="text-[#001f8ed6] text-[3.4vh] md:text-[4vh]  float-left font-semibold w-[80%] ">
                  Easy
                </h1>
                <img
                  src={icon3}
                  className="float-right w-[14%] md:w-[17%] lg:w-[20%]"
                  alt="suitcase"
                />
                <h1 className="text-[#001f8ed6] text-[3vh] md:text-[3.5vh] float-left font-semibold w-[80%] ">
                  good tracking
                </h1>

              </div>
              <p className="mt-[2.5vh] text-[#5b5b5b] text-[2.7vh] md:text-[2.6vh] lg:text-[3vh]">
                We are pleased to welcome new distributors
              </p>
            </div>

            <div className="bg-[#9CE7FF33] flex flex-col h-[35vh] justify-evenly p-[3vh] shadow-xl">
              <div className="float">
                <h1 className="text-[#001f8ed6] text-[3.4vh] md:text-[4vh]  float-left font-semibold w-[80%] ">
                  WHO-GMP
                </h1>
                <img
                  src={icon4}
                  className="float-right w-[14%] md:w-[17%] lg:w-[20%]"
                  alt="suitcase"
                />
                <h1 className="text-[#001f8ed6] text-[3vh] md:text-[3.5vh]  float-left font-semibold w-[80%] ">
                  products .
                </h1>

              </div>
              <p className="mt-[2.5vh] text-[#5b5b5b] text-[2.7vh] md:text-[2.6vh] lg:text-[3vh]">
                We provide complete formulation of products
              </p>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}
