const data = [
        { id: 1, GENERIC_NAME: "ACETATE RINGER", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500 ML" },
        { id: 2, GENERIC_NAME: "ALL ESSENTIAL AND NONESSENTIAL PURE AMINO ACID FOR INTRAVENOUS INFUSION (10%)", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500ML" },
        { id: 3, GENERIC_NAME: "AMINO ACID (10%W/V) INJECTION WITH ELECTROLYTES", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500ML" },
        { id: 4, GENERIC_NAME: "AMINO ACID INFUSION 8%W/V", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500ML" },
        { id: 5, GENERIC_NAME: "AMINO ACID 5% W/V AND SORBITOL 5% W/V I.V. INFUSION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500ML" },
        { id: 6, GENERIC_NAME: "AMINO ACID INJECTION 7%W/V", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500ML" },
        { id: 7, GENERIC_NAME: "ATROPINE SULPHATE INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/300/500 ML" },
        { id: 8, GENERIC_NAME: "CIPROFLOXACIN INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/300/500 ML" },
        { id: 9, GENERIC_NAME: "COMPOUND SODIUM CHLORIDE INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/540/1000 ML" },
        { id: 10, GENERIC_NAME: "COMPOUND SODIUM LACTATE & DEXTROSE INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/540/1000 ML" },
        { id: 11, GENERIC_NAME: "COMPOUND SODIUM LACTATE INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500/1000 ML" },
        { id: 12, GENERIC_NAME: "DEXTRAN 40 & DEXTROSE 5% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/1000 ML" },
        { id: 13, GENERIC_NAME: "DEXTRAN 40 & SODIUM CHLORIDE 0.9% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/1000 ML" },
        { id: 14, GENERIC_NAME: "DEXTROSE 10% & SODIUM CHLORIDE 0.9% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/540/1000 ML" },
        { id: 15, GENERIC_NAME: "DEXTROSE 10% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500/1000 ML" },
        { id: 16, GENERIC_NAME: "DEXTROSE 25% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500 ML" },
        { id: 17, GENERIC_NAME: "DEXTROSE 5% & SODIUM CHLORIDE 0.45% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/540/1000 ML" },
        { id: 18, GENERIC_NAME: "DEXTROSE 5% & SODIUM CHLORIDE 0.9% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500/1000 ML" },
        { id: 19, GENERIC_NAME: "DEXTROSE 5% INJECTION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500/1000 ML" },
        { id: 20, GENERIC_NAME: "ESSENTIAL AMINO ACID FOR PARENTERAL NUTRITION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100/250/500ML" },
        { id: 21, GENERIC_NAME: "FLUCONAZOLE I.V. INFUSION", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "100 ML" },
        { id: 22, GENERIC_NAME: "GLUCOSE INTRAVENOUS INFUSION BP (10.0 % W/V)", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/1000 ML" },
        { id: 23, GENERIC_NAME: "GLUCOSE INTRAVENOUS INFUSION BP (5.0 % W/V)", STRENGTH: "IP/BP/USP", PACK_STYLE: "GLASS/FFS", PACKING: "500/1000 ML" },
  
];
export default data;