const data =[

        { id: 1, GENERIC_NAME: "BECLOMETHASONE DIPROPINATE MONOHYDRATE CAPSULES 200 MCG / 400 MCG", PACKING: "DRY POWDER INHALERS" },
        { id: 2, GENERIC_NAME: "BECLOMETHASONE DIPROPIONATE 100 MCG & FORMOTEROL FUMARATE 6 MCG INHALERS", PACKING: "DRY POWDER INHALERS" },
        { id: 3, GENERIC_NAME: "BECLOMETHASONE DIPROPIONATE 50 MCG & LEVOSALBUTAMOL 50 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 4, GENERIC_NAME: "BECLOMETHASONE METERED DOSE INHALATION 50 MCG", PACKING: "DRY POWDER INHALERS" },
        { id: 5, GENERIC_NAME: "BUDESONIDE INHALATION I.P 200 MCG", PACKING: "DRY POWDER INHALERS" },
        { id: 6, GENERIC_NAME: "BUDESONIDE POWDER FOR INHALATION 100/200 MCG", PACKING: "DRY POWDER INHALERS" },
        { id: 7, GENERIC_NAME: "CICLESONIDE 160 MCG AND FORMOTEROL FUMARATE 4.5 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 8, GENERIC_NAME: "CICLESONIDE 200 MCG AND FORMOTEROL FUMARATE 4.5 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 9, GENERIC_NAME: "CICLESONIDE 400 MCG AND FORMOTEROL FUMARATE 4.5 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 10, GENERIC_NAME: "CICLESONIDE 80 MCG AND FORMOTEROL FUMARATE 4.5 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 11, GENERIC_NAME: "CROMOLYN SODIUM INHALATION POWDER 20 MG", PACKING: "DRY POWDER INHALERS" },
        { id: 12, GENERIC_NAME: "FLUTICASONE PROPIONATE 100MCG & FORMOTEROL FUMARATE 6MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 13, GENERIC_NAME: "FLUTICASONE PROPIONATE 125 MCG & FORMOTEROL FUMARATE 6 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 14, GENERIC_NAME: "FLUTICASONE PROPIONATE 250 MCG & FORMOTEROL FUMARATE 6 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 15, GENERIC_NAME: "FLUTICASONE PROPIONATE 50 MCG & FORMOTEROL FUMARATE 6 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 16, GENERIC_NAME: "FLUTICASONE PROPIONATE 500 MCG & FORMOTEROL FUMARATE 6 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 17, GENERIC_NAME: "FORMOTEROL FUMARATE & BUDESONIDE POWDER FOR INHALATION 6 MCG +100MCG / 6MCG+200MCG / 6MCG+400 MCG / 12MCG+400MCG", PACKING: "DRY POWDER INHALERS" },
        { id: 18, GENERIC_NAME: "FORMOTEROL FUMARATE & FLUTICASONE POWDER FOR INHALATION 6/6 MCG + 100/250 MCG", PACKING: "DRY POWDER INHALERS" },
        { id: 19, GENERIC_NAME: "FORMOTEROL FUMARATE 4.5 MCG & BUDESONIDE 160 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 20, GENERIC_NAME: "FORMOTEROL FUMARATE 4.5 MCG & BUDESONIDE 80 MCG INHALER", PACKING: "DRY POWDER INHALERS" },
        { id: 21, GENERIC_NAME: "FORMOTEROL FUMARATE 6 MCG & BUDESONIDE 100 MCG INHALATION", PACKING: "DRY POWDER INHALERS" },
        { id: 22, GENERIC_NAME: "FORMOTEROL FUMARATE 6 MCG & BUDESONIDE 200 MCG INHALATION", PACKING: "DRY POWDER INHALERS" },
        { id: 23, GENERIC_NAME: "FORMOTEROL FUMARATE 6 MCG & BUDESONIDE 400 MCG INHALATION", PACKING: "DRY POWDER INHALERS" },
     
      
]
export default data;