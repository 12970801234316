import React,{useState} from 'react';
import "./Navbar.css"
import logo from "../../assets/logo.png"
import search from "../../assets/search.png"
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {

  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setIsOpen(false);
  }


  // const handleSearch = () => {
  //   toast.error("Something went wrong!", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // }

  return (
    <div className='navbar pt-[0vhz] md:pt-[2vh] pb-[0vh] md:pb-[3vh]'>
    <div className="w-full md:w-[90%] mx-auto pt-[1vh]">
      <div className='p-[2vh] flex flex-col md:flex-row justify-between'>
        <div className='flex flex-row justify-between items-center'>
          <Link to='/'>
          <img 
            src={logo} 
            className='w-[20vh] md:w-[20vw] lg:w-[15vw]' alt="pelikaslogo"
          />
          </Link>

          <div
            className={`hamburger ${isOpen ? 'open' : ''}`}
            onClick={toggleNav}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        
        <div className={`search w-[85%] md:w-[60%] h-[5vh] flex flex-row mt-[5vh] md:mt-[0vh] mx-auto md:mx-[0vh] ${isOpen ? 'visible' : 'hidden'}`}>
          <input 
            type="text" 
            className='searchbar h-[5vh] w-[90%] px-[2vh] focus:outline-none mr-[1vh]' 
            placeholder='Search Products, medicines etc'
          />
          <button 
            className='searchbtn flex flex-row justify-center items-center w-[5vh] bg-black'>
          <Link to='/products'>
            <img src={search} alt="" className='w-[3vh] h-[3vh]'
            // onClick ={handleSearch}
            />
          </Link>
          </button>
          {/* <ToastContainer toastClassName="custom-toast" /> */}
        </div>
    

      </div>

      <div className='mt-[1.5vh]'>
        
          <ul className={`nav-links flex flex-col items-center md:flex-row justify-around ${isOpen ? 'visible' : 'hidden'}`}>

            <li className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === '/' ? 'active' : ''}`}>
              <Link to='/' data-tab="home" onClick={closeNav}>Home</Link>
            </li>
            
            <li className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === '/about' ? 'active' : ''}`}>
              <Link to='/about' onClick={closeNav}>About Us</Link>
            </li>

            <li className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === '/our-expertise' ? 'active' : ''}`}>
              <Link to='/our-expertise' onClick={closeNav}>Our Expertise</Link>
            </li>

            <li className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === '/products' ? 'active' : ''}`}>
              <Link to='/products' onClick={closeNav}>Products-Portfolio</Link>
            </li>

            <li className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === '/services' ? 'active' : ''}`}>
              <Link to='/services' onClick={closeNav}>Services</Link>
            </li>

            <li className={`text-[2.3vh] mb-[3vh] md:mb-[0vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === '/contact' ? 'active' : ''}`}>
              <Link to='/contact' onClick={closeNav}>Contact</Link>
            </li>

          </ul>
        
        <div className={`underline-bar ${location.pathname === '/' ? 'home' : location.pathname.substring(1)}`}></div>
      </div>

    </div>
    </div>
  )
}
