import React from 'react';

export default function ProductMain({subtitle, subcategories}) {
  return (
    <div className='bg-white pt-[1vh]'>
      
      <div>
        <h2 className=" text-[3vh] font-medium text-gray-600 mx-[2.5vh] p-[1vh]">
          {subtitle}
        </h2>

        <div className='flex flex-row flex-wrap align-middle items-center justify-evenly mx-[2vh] pb-[2vh]'>

          {subcategories.map((item,index)=>(
            <div 
              key={index}
              className="flex flex-col w-[28vh] mx-[1vh]"
            >
              <img 
                src={item.image} 
                className="w-[30vh] h-[28vh] border-[0.2vh] border-gray-300 mb-[1vh]"
                alt='ProductImage'
              />
              <h2 className="text-center text-[2vh] text-gray-800">
                {item.name}
              </h2>
            </div>
          ))}
          
        </div>

      </div>
    
    </div>
  )
}
