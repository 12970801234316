const data = [
    {
        id: 1,
        GENERIC_NAME: "ACEBROPHYLINE 100MG",
        STRENGTH: "BLISTER/STRIP/BOTTLES",
        TYPE: "HARD GELATIN CAPSULES",
    },
    {
        id: 1,
        GENERIC_NAME: "ACEBROPHYLINE 100MG",
        STRENGTH: "BLISTER/STRIP/BOTTLES",
        TYPE: "HARD GELATIN CAPSULES",
    },
    {
        id: 2,
        GENERIC_NAME: "AMOXYCILLIN & SALBACTUM INJ.",
        STRENGTH: "300MG / 3GM / 4.5GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 3,
        GENERIC_NAME: "AMOXYCILLIN SODIUM INJ.",
        STRENGTH: "250MG/ 500MG / 1.0GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 4,
        GENERIC_NAME: "AMPICILLIN & CLOXACILLIN FOR INJ.",
        STRENGTH: "250MG/ 500MG / 1.0GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 5,
        GENERIC_NAME: "AMPICILLIN & SALBACTUM FOR INJ.",
        STRENGTH: "0.75GM / 1.5GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 6,
        GENERIC_NAME: "AMPICILLIN SODIUM INJ.",
        STRENGTH: "250MG/ 500MG / 1.0GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 7,
        GENERIC_NAME: "CLOXACILLIN SODIUM INJ.",
        STRENGTH: "250MG/ 500MG / 1.0GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 8,
        GENERIC_NAME: "IMEPENEM AND CILASTATIN FOR INJ.",
        STRENGTH: "1GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 9,
        GENERIC_NAME: "MEROPENEM & SULBACTAM INJ.",
        STRENGTH: "1.5GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 10,
        GENERIC_NAME: "MEROPENEM FOR INJ.",
        STRENGTH: "125 MG / 500MG / 1GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 11,
        GENERIC_NAME: "PIPERACILLIN & TAZOBACTUM FOR INJ.",
        STRENGTH: "1.125GM / 2.25GM / 4.5GM",
        TYPE: "BETA LACTAM",
    },
    {
        id: 12,
        GENERIC_NAME: "TAZOBACTAM SODIUM INJ",
        STRENGTH: "2.0GM / 1.0GM / 500MG",
        TYPE: "BETA LACTAM",
    },
    {
        id: 13,
        GENERIC_NAME: "CEFAZOLIN SODIUM INJ.",
        STRENGTH: "1.0GM",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 14,
        GENERIC_NAME: "CEFEPIME & SULBACTAM FOR INJ.",
        STRENGTH: "1125 MG",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 15,
        GENERIC_NAME: "CEFEPIME & TAZOBACTAM INJ",
        STRENGTH: "1125MG / 562.5MG / 281.25MG",
        TYPE: "CEPHALOSPORINS",
    },

    {
        id: 16,
        GENERIC_NAME: "CEFEPIME FOR INJ.",
        STRENGTH: "1.0GM",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 17,
        GENERIC_NAME: "CEFOPERAZONE & SULBACTAM FOR INJ.(1:1)",
        STRENGTH: "1.0GM/ 2.0GM",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 18,
        GENERIC_NAME: "CEFOPERAZONE & SULBACTAM FOR INJ.(2:1)",
        STRENGTH: "1.5GM/ 3.0G",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 19,
        GENERIC_NAME: "CEFOPERAZONE & TAZOBACTAM FOR INJ.",
        STRENGTH: "281.25MG/562.5MG/1125MG",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 20,
        GENERIC_NAME: "CEFOPERAZONE FOR INJ",
        STRENGTH: "250MG/ 1.0GM",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 21,
        GENERIC_NAME: "CEFOTAXIME & SULBACTAM FOR INJ.",
        STRENGTH: "375MG/750MG/ 1.5GM",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 22,
        GENERIC_NAME: "CEFOTAXIME SODIUM INJ.",
        STRENGTH: "125MG/250MG/500MG/ 1.0GM",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 23,
        GENERIC_NAME: "CEFPIROME SULPHATE FOR INJ.",
        STRENGTH: "1.0GM",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 24,
        GENERIC_NAME: "CEFTAZIDIME & SULBACTAM INJ.",
        STRENGTH: "1500MG / 750MG / 375MG",
        TYPE: "CEPHALOSPORINS",
    },
    {
        id: 25,
        GENERIC_NAME: "CEFTAZIDIME & TAZOBACTAM INJ.",
        STRENGTH: "1125MG / 562.5MG / 281.25MG",
        TYPE: "CEPHALOSPORINS",
    },


]

export default data;