import React, { useState } from "react";


import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function SideBar({onTabSelect}) {

  const [expanded, setExpanded] = useState("panel1");

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleTabClick = (tabName) => {
    onTabSelect(tabName);

  };

  return (
    <div className="sidebar w-[100%] md:w-[25%] h-auto  bg-white shadow-xl">
      {/* Enternal Formulations */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordianChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordian"
          style={{ background: "linear-gradient(90deg, rgba(0, 194, 255, 0.20) 0%, rgba(0, 139, 14, 0.08) 100%)", color: "#111" }}
          onClick={() => handleTabClick('enternal-formulations')}
        >

          <h1 
            className="text-[2.3vh] md:text-[2.5vh]" 
          >
            Enternal Formulations
          </h1>

        </AccordionSummary>
        <AccordionDetails className="text-[#737272]">

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('enternal-formulations/tablets')}
          >
            Tablets
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline" 
            onClick={() => handleTabClick('enternal-formulations/capsules')}
          >
            Capsules
          </h1>

          <h1
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('enternal-formulations/sachets')}
          >
            Sachets
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('enternal-formulations/lozenges')}
          >
            Lozenges
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('enternal-formulations/suppositories')}
          >
            Suppositories/Pessary
          </h1>

        </AccordionDetails>
      </Accordion>

      {/* Parental Formulations */}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleAccordianChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "linear-gradient(90deg, rgba(0, 194, 255, 0.20) 0%, rgba(0, 139, 14, 0.08) 100%)", color: "#111" }}
          onClick={() => handleTabClick('parental-formulations')}
        >

          <h1 
            className="text-[2.3vh] md:text-[2.5vh]"
          >
            Parental Formulations
          </h1>

        </AccordionSummary>

        <AccordionDetails className="text-[#737272]">

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('parental-formulations/dry-powder-injectables')}
          >
            Dry Powder Injectables (VIALS/AMPS)
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('parental-formulations/liquid-injectables')}
          >
            Liquid Injectables (VIALS/AMPS)
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('parental-formulations/prefilled-syringes')}
          >
            Prefilled Syringes (PFS)
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('parental-formulations/infusions')}
          >
            Infusions
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('parental-formulations/contrast-media')}
          >
            Contrast Media
          </h1>

        </AccordionDetails>
      </Accordion>

      {/* Inhalational Formulations */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleAccordianChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "linear-gradient(90deg, rgba(0, 194, 255, 0.20) 0%, rgba(0, 139, 14, 0.08) 100%)", color: "#111" }}
          onClick={() => handleTabClick('inhalational-formulations')}
        >
          <h1 
            className="text-[2.3vh] md:text-[2.5vh]"
          >
            Inhalational Formulations
          </h1>

        </AccordionSummary>
        <AccordionDetails className="text-[#737272]">

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('inhalational-formulations/repsules')}
          >
            Repsules
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('inhalational-formulations/rotacaps')}
          >
            RotaCaps
          </h1>

          <h1  
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('inhalational-formulations/inhalers')}
          >
            Inhalers
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('inhalational-formulations/nasal-sprays')}
          >
            Nasal Sprays
          </h1>

        </AccordionDetails>
      </Accordion>

      {/* Topical (Skin/hair) Formulations */}
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleAccordianChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "linear-gradient(90deg, rgba(0, 194, 255, 0.20) 0%, rgba(0, 139, 14, 0.08) 100%)", color: "#111" }}
          onClick={() => handleTabClick('topical-formulations')}
        >

          <h1 
            className="text-[2.3vh] md:text-[2.5vh]"
          > 
            Topical (SKIN/HAIR) Formulations
          </h1>

        </AccordionSummary>
        <AccordionDetails className="text-[#737272]">

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('topical-formulations/creams')}
          >
            Cream/Ointment/Gels
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('topical-formulations/lotions')}
          >
            Lotions/Powder
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('topical-formulations/serums')}
          >
            Serums/Drops/Sprays
          </h1>

        </AccordionDetails>
      </Accordion>

      {/* Opthalmic & ENT Products */}
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleAccordianChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "linear-gradient(90deg, rgba(0, 194, 255, 0.20) 0%, rgba(0, 139, 14, 0.08) 100%)", color: "#111" }}
          onClick={() => handleTabClick('opthalmic-products')}
        >
          <h1 
            className="text-[2.3vh] md:text-[2.5vh]"
          >
            Opthalmic & ENT Products
          </h1>
        </AccordionSummary>
        <AccordionDetails className="text-[#737272]">

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('opthalmic-products/eye-ear')}
          >
            Eye/Ear Drops
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('opthalmic-products/implants')}
          >
            Implants/Lenses
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('opthalmic-products/intraocular-solutions')}
          >
            Intraocular Solutions
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('opthalmic-products/consumables')}
          >
            Opthalmic Consumables & Instrument
          </h1>

        </AccordionDetails>
      </Accordion>

      {/* Others */}
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleAccordianChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "linear-gradient(90deg, rgba(0, 194, 255, 0.20) 0%, rgba(0, 139, 14, 0.08) 100%)", color: "#111" }}
          onClick={() => handleTabClick('others')}
        >

          <h1 
            className="text-[2.3vh] md:text-[2.5vh]"
          >
            Others
          </h1>

        </AccordionSummary>
        <AccordionDetails className="text-[#737272]">
          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('others/surgical')}
          >
            Surgical Consumables
          </h1>

          <h1 
            className="text-[2.3vh] md:text-[2.5vh] mt-[1vh] cursor-pointer hover:underline"
            onClick={() => handleTabClick('others/hospital')}
          >
            Hospital Technology
          </h1>

        </AccordionDetails>
      </Accordion>
    </div>
  );
}
