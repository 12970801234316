import React from "react";
import "./AboutUs.css";
import mission from "../../assets/mission.png";
import vission from "../../assets/vission.png";
import values from "../../assets/values.png";
import Lottie from "lottie-react";
import globe from '../../assets/globe.json'

export default function AboutUs() {
  return (
    <div >
      <h1 className="gradient mx-auto text-center text-[3vh] font-bold mt-[4vh] w-[80vw] md:text-[3vh] lg:text-[5vh] m-[5vh]">
          WELCOME TO PELIKAS HEALTHCARE SOLUTION LTD.
        </h1>
      
        <div className="flex flex-wrap justify-center items-center flex-col md:flex-row lg:flex-row ">
          <div className="w-[60%] md:w-[35%] lg:w-[30%] mx-auto ">
            < Lottie animationData={globe} className="w-[100%] "/>
          </div>
          <div className="m-[5vh] w-[80%] md:w-[60%]">
            <p className="text-justify text-[]">
              Pelikas Healthcare Pvt. Ltd., a trailblazing pharmaceutical
              company with over years of manufacturing and marketing expertise.
              From our Mumbai-based head office in Maharashtra, India, we are
              revolutionizing the industry by delivering products that surpass
              international standards of excellence. As proud bearers of ISO and
              GDP compliance, we leave no stone unturned in ensuring the highest
              levels of safety, quality, and efficacy for our valued customers
              Our state-of-the-art manufacturing units boast prestigious
              certifications such as WHO-GMP and GLP, reflecting our unwavering
              commitment to global best practices.Our extensive range of over
              5,000 healthcare products includes tablets, capsules, lozenges,
              sachets, suppositories/pessaries, and dry powder injectables
              (vials/amps), liquid injectables (vials/amps), prefilled syringes
              (PFS), infusions, contrast media, repulses, rotacaps, inhalers,
              nasal sprays, cream/ointments/gels, lotions/powder,
              serum/drops/sprays, eye/ear drops, implants / lenses, intraocular
              solutions, ophthalmic consumables & instruments, etc. We are
              driven by a passion to transform lives through innovative
              healthcare solutions that make a tangible difference. Join us on
              this remarkable journey towards a healthier future! Experience
              excellence in every dose – choose Pelikas Healthcare for your
              healthcare needs.
            </p>
          </div>
        </div>
      

      {/* MISSION */}
      <div className="w-[80vw] my-[8vh] mx-auto bg-gradient-to-br  from-[#abdcea34] to-[#00c3ff2e] shadow-lg p-[2vh]">
        <h1 className="text-[#001F8E] mx-auto text-center text-[5vh] font-bold mt-[2vh] ">
          MISSION
        </h1>

        <div className="flex flex-col justify-center lg:flex-row md:flex-col m-[2vh] ">
          <img src={mission} className="w-[50vh] mx-[1vh]" alt="mission" />
          <p className="m-[1vh] text-justify">
            Introducing our revolutionary business concept: a comprehensive
            online platform that simplifies the procurement of pharmaceuticals
            and healthcare supplies for individuals and businesses alike. Our
            mission is to offer a one-stop shop, ensuring convenience,
            reliability, and exceptional customer service. At our core, we
            prioritize the delivery of superior quality products at competitive
            rates. Our team of skilled people meticulously curates a range of
            pharmaceuticals and healthcare supplies to meet your specific needs.
            With our extensive selection, you can trust that all your medical
            requirements will be fulfilled with utmost precision. In addition to
            offering top-notch products, we are dedicated to providing an
            unparalleled level of customer service. Our knowledgeable and
            responsive team is readily available to address any inquiries or
            concerns you may have throughout the purchasing process. Experience
            the convenience and peace of mind that comes with accessing
            high-quality medical equipment and supplies from a trusted source.
            Join us as we revolutionize the industry, delivering excellence in
            every transaction.
          </p>
        </div>
      </div>

      {/* VISSION */}
      <div className=" w-[80vw] my-[8vh] mx-auto bg-gradient-to-br  from-[#abdcea34] to-[#00c3ff2e] shadow-lg p-[2vh]">
        <h1 className="text-[#001F8E] mx-auto text-center text-[5vh] font-bold mt-[2vh] ">
          VISSION
        </h1>

        <div className="flex flex-col-reverse justify-center lg:flex-row md:flex-col m-[2vh] ">
          <p className="m-[1vh] text-justify mt-[3vh] md:mt-[0vh] md:mr-[6vh] text-[2.4vh]">
            Our vision is to be a leading pharmaceutical company in India and to
            become a significant global player by providing high quality,
            affordable and innovative solutions in medicine and treatment."We
            believe that complete customer satisfaction is the key to expand
            business. Our actions are driven by these core values:
            <li className="mt-[1vh] ml-[4vh]"> Service</li>
            <li className="mt-[1vh] ml-[4vh]"> Integrity</li>
            <li className="mt-[1vh] ml-[4vh]"> Quality</li>
            <li className="mt-[1vh] ml-[4vh]"> Customer Satisfaction</li>
          </p>

          <img src={vission} className="w-[30vh] mx-auto" alt="mission" />
        </div>
      </div>

      {/* OUR VALUES */}
      <div className=" w-[80vw] my-[8vh] mx-auto bg-gradient-to-br  from-[#abdcea34] to-[#00c3ff2e] shadow-lg p-[2vh]">
        <h1 className="text-[#001F8E] mx-auto text-center text-[5vh] font-bold mt-[2vh] ">
          OUR VALUES
        </h1>

        <div className="flex flex-col justify-evenly lg:flex-row md:flex-col m-[2vh] ">
          <img src={values} className="w-[30vh] mx-auto" alt="mission" />
          <div className="flex flex-col w-[100%] md:ml-[3vh] mt-[3vh] md:mt-[0vh]">
            <p className="m-[1vh] text-justify text-[2.4vh]">
              Our company is committed to provide best pharma products at
              affordable prices to the community with a view to help bring down
              the healthcare costs.
            </p>
            <p className="m-[1vh] text-justify text-[2.4vh]">
              We are a group of passionate and talented individuals dedicated to
              improving patient outcomes through the use of the most effective
              pharmaceuticals and production techniques.
            </p>
            <p className="m-[1vh] text-justify text-[2.4vh]">
              Pelikas Healthcare Pvt. Ltd. employs approximately 200 employees
              at its manufacturing facilities. The strength of our organization
              is our highly experienced team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
