import React from 'react'


const data = [
  { id: 1, GENERIC_NAME: "IOPAMIDOL INJECTION USP (300MGI/ML, 350MGI/ML, 370MGI/ML)", TYPE: "NON-IONIC CONTRAST MEDIA", PACK_STYLE: "20 ML VIAL, 50 ML VIAL, 90 ML VIAL" },
  { id: 2, GENERIC_NAME: "IOHEXOL INJECTION USP (240MGI/ML, 300MGI/ML, 350MGI/ML, 370MGI/ML)", TYPE: "NON-IONIC CONTRAST MEDIA", PACK_STYLE: "20 ML VIAL, 30 ML VIAL, 50 ML VIAL, 90 ML VIAL" },
  { id: 3, GENERIC_NAME: "IODIXANOL INJECTION USP (270MGI/ML, 320 MGI/ML)", TYPE: "NON-IONIC CONTRAST MEDIA", PACK_STYLE: "50 ML VIAL, 90 ML VIAL" },
  { id: 4, GENERIC_NAME: "IOVERSOL INJECTION USP (320MGI/ML, 350MGI/ML)", TYPE: "NON-IONIC CONTRAST MEDIA", PACK_STYLE: "50 ML VIAL, 90 ML VIAL" },
  { id: 5, GENERIC_NAME: "IOPROMIDE INJECTION USP (300MGI/ML, 370MGI/ML)", TYPE: "NON-IONIC CONTRAST MEDIA", PACK_STYLE: "50 ML VIAL, 90 ML VIAL" },
  { id: 6, GENERIC_NAME: "GADOPENTETATE DIMEGLUMINE INJECTION USP", TYPE: "MRI CONTRAST MEDIA", PACK_STYLE: "10 ML VIAL, 20 ML VIAL" },
  { id: 7, GENERIC_NAME: "GADOTERATE MEGLUMINE INJECTION", TYPE: "MRI CONTRAST MEDIA", PACK_STYLE: "10 ML VIAL, 20 ML VIAL" },
  { id: 8, GENERIC_NAME: "GADODIAMIDE INJECTION USP", TYPE: "MRI CONTRAST MEDIA", PACK_STYLE: "10 ML VIAL, 20 ML VIAL" },
  { id: 9, GENERIC_NAME: "DIATRIZOATE MEGLUMINE & DIATRIZOATE SODIUM INJ. USP (60% & 76%)", TYPE: "IONIC CONTRAST MEDIA", PACK_STYLE: "20 ML V/A, 50 ML VIAL, 90 ML VIAL" },
  { id: 10, GENERIC_NAME: "DIATRIZOATE MEGLUMINE INJECTION USP (65%)", TYPE: "IONIC CONTRAST MEDIA", PACK_STYLE: "20 ML V/A, 50 ML VIAL, 90 ML VIAL" },
  { id: 11, GENERIC_NAME: "DITRIZOATE MEGLUMINE 65% INJECTION USP & CANA2 EDTA STABILIZER", TYPE: "IONIC CONTRAST MEDIA", PACK_STYLE: "20ML VIAL, 50ML VIAL" },
  { id: 12, GENERIC_NAME: "DIATRIZOATE SODIUM ORAL SOLUTION USP", TYPE: "ORAL CONTRAST MEDIA", PACK_STYLE: "30 ML BOTTLE, 60 ML BOTTLE, 100 ML BOTTLE" },
  { id: 13, GENERIC_NAME: "DIATRIZOATE MEGLUMINE & DIATRIZOATE SODIUM ORAL SOLUTION USP", TYPE: "ORAL CONTRAST MEDIA", PACK_STYLE: "30 ML BOTTLE, 60 ML BOTTLE, 100 ML BOTTLE" },
];


const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>TYPE</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>PACK_STYLE</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.TYPE}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.PACK_STYLE}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Sachets() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>CONTRAST MEDIA</h1>
      <div className="App">
        <Table data={data} />
      </div>

    </div>
  )
}
