import React, { useState } from "react";
import "./Contact.css";
import Lottie from "lottie-react";
import TextField from "@mui/material/TextField";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import animate from "../../assets/contact-email.json";



export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [message, setMessage] = useState("");

  const handleSendMessage = () => {
    if (!name || !email || !contactNo || !message) {
      toast.error("Please enter all details.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    toast.success("Message sent successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

    setName("");
    setEmail("");
    setContactNo("");
    setMessage("");
  };

  return (
    <div>
      <div className="flex flex-row align-middle justify-center">
        <h1 className="text-[4vh] m-[2vh] my-auto md:text-[6vh] lg:text-[7vh]  gradient text-center font-bold lg:m-[4vh]">
          GET IN TOUCH WITH US
        </h1>
        <Lottie animationData={animate} className="w-[30%] md:w-[10%]" />
      </div>
      <div className="flex flex-col justify-center items-center md:flex-col lg:flex-row m-[2vh]">
        <div className="w-[100%] md:w-[80%] lg:w-[40%] mx-auto">
          <p className="text-[2.5vh] lg:text-[3vh]">
            Dear Pelikas customer, Thank you for reaching out! You are an
            invaluable part of everything we do here. And we’re absolutely
            thrilled to hear from you.
          </p>
          <div className="my-[4vh] ">
            <h2 className="text-xl font-semibold">Our Location : </h2>
            <p>
              Gupta House No. 4702, Jadhav Pada, Dhaniv Baug, Pelhar, Vasai Road
              (East), Vasai, Thane : 401 202 Maharashtra, INDIA
            </p>
          </div>

          <div className="my-[4vh] flex flex-row">
            <h2 className="text-xl font-semibold">Phone Number : </h2>
            <p> (+91) 84549 68736</p>
          </div>

          <div className="my-[4vh] flex flex-row">
            <h2 className="text-xl font-semibold">Email Address : </h2>
            <p> pelikashealthcare@gmail.com</p>
          </div>
        </div>

        <div className="w-[100%] md:w-[80%] lg:w-[40%] bg-[#eafff0] shadow-lg rounded-lg px-[4vh] py-[2vh] mx-auto">
          <div className="my-[3vh]">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              className="input w-[100%]"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {/* <div className="error-text">{nameError}</div> */}
          </div>

          <div className="my-[3vh]">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              className="input w-[100%]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <div className="error-text">{emailError}</div> */}
          </div>

          <div className="my-[3vh]">
            <TextField
              id="outlined-basic"
              label="Contact No."
              variant="outlined"
              className="input w-[100%]"
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
            />
            {/* <div className="error-text">{contactNoError}</div> */}
          </div>

          <div className="my-[3vh]">
            <TextField
              id="outlined-multiline-static"
              label="Message"
              multiline
              rows={4}
              className="input w-[100%]"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {/* <div className="error-text">{messageError}</div> */}
          </div>

          <button
            className="button text-[2.3vh] rounded-md px-[1vh] md:px-[1.5vh] py-[1vh] md:text-[2.6vh] text-white"
            onClick={handleSendMessage}
          >
            Send Message
          </button>

          <ToastContainer toastClassName="custom-toast" />
        </div>
      </div>
    </div>
  );
}
