const data = [
        { id: 1, GENERIC_NAME: "ADULT DIAPER SIZE: MEDIUM", STRENGTH: "PKT OF 10 PCS" },
        { id: 2, GENERIC_NAME: "ADULT DIAPER SIZE: LARGE", STRENGTH: "PKT OF 10 PCS" },
        { id: 3, GENERIC_NAME: "ADULT DIAPER SIZE: EXTRA LARGE", STRENGTH: "PKT OF 10 PCS" },
        { id: 4, GENERIC_NAME: "PANT TYPE ADULT DIAPER : MEDIUM", STRENGTH: "PKT OF 10 PCS" },
        { id: 5, GENERIC_NAME: "PANT TYPE ADULT DIAPER : LARGE", STRENGTH: "PKT OF 10 PCS" },
        { id: 6, GENERIC_NAME: "PANT TYPE ADULT DIAPER : EXTRA LARGE", STRENGTH: "PKT OF 10 PCS" },
        { id: 7, GENERIC_NAME: "UNDER PAD 60 X 90 CM", STRENGTH: "PKT OF 10 PCS" },
        { id: 8, GENERIC_NAME: "FACE MASK: 3PLY (LACE/LOOP): BULK PACK (WITH BACTERIA FILTER)", STRENGTH: "PACK OF 100 PCS" },
        { id: 9, GENERIC_NAME: "FACE MASK: 3PLY (LACE/LOOP): BULK PACK (WITHOUT BACTERIA FILTER)", STRENGTH: "PACK OF 100 PCS" },
        { id: 10, GENERIC_NAME: "FACE MASK: 3PLY (LACE/LOOP): IND BOX (WITH BACTERIA FILTER)", STRENGTH: "BOX OF 100 PCS" },
        { id: 11, GENERIC_NAME: "FACE MASK: 3PLY (LACE/LOOP): IND BOX (WITHOUT BACTERIA FILTER)", STRENGTH: "BOX OF 100 PCS" },
        { id: 12, GENERIC_NAME: "N-95 FACE MASK EAR LOOP/HEAD LOOP", STRENGTH: "BOX OF 25 PCS" },
        { id: 13, GENERIC_NAME: "SURGEON CAP: BULK PACK", STRENGTH: "PACK OF 100 PCS" },
        { id: 14, GENERIC_NAME: "SURGEON CAP : IND BOX", STRENGTH: "BOX OF 100 PCS" },
        { id: 15, GENERIC_NAME: "BOUFFANT CAP: BULK PACK", STRENGTH: "PACK OF 100 PCS" },
        { id: 16, GENERIC_NAME: "BOUFFANT CAP : IND BOX", STRENGTH: "BOX OF 100 PCS" },
        { id: 17, GENERIC_NAME: "H.I.V. KIT STERILE", STRENGTH: "-" },
        { id: 18, GENERIC_NAME: "KNEE LENGTH SHOE COVER BULK PACK", STRENGTH: "PKT OF 25 PAIR" },
        { id: 19, GENERIC_NAME: "DISPOSABLE STERILE PLASTIC APRON", STRENGTH: "-" },
        { id: 20, GENERIC_NAME: "NON-WOVEN APRON", STRENGTH: "-" },
        { id: 21, GENERIC_NAME: "DISPOSABLE GOWN : 40 GSM", STRENGTH: "-" },
        { id: 22, GENERIC_NAME: "BED SHEET (PP) SIZE: 100CM X 150CM", STRENGTH: "-" },
        { id: 23, GENERIC_NAME: "BED SHEET (PP) SIZE: 120CM X 200CM", STRENGTH: "-" },
        { id: 24, GENERIC_NAME: "BED SHEET (PP) SIZE: 140CM X 150CM", STRENGTH: "-" },
        { id: 25, GENERIC_NAME: "NON-WOVEN BED SHEET: 120CM X 200CM", STRENGTH: "-" },
        { id: 26, GENERIC_NAME: "STERILE RUBBER GLOVE SIZE:6\", 6 ½\", 7\", 7 ½\" & 8", STRENGTH: "BOX OF 25 PAIR" },
        { id: 27, GENERIC_NAME: "NONSTERILE RUBBER GLOVE SIZE:6\", 6 ½\", 7\", 7 ½\" 8", STRENGTH: "BOX OF 25 PAIR" },
        { id: 28, GENERIC_NAME: "LATEX EXAMINATION GLOVES (S/ M/ L)", STRENGTH: "BOX OF 100 PCS" },
        { id: 29, GENERIC_NAME: "NITRILE GLOVES – (S/ M/ L)", STRENGTH: "BOX OF 100 PCS" },
        { id: 30, GENERIC_NAME: "PLASTIC GLOVES SIZE: 11\"X12\" BULK BOX", STRENGTH: "BOX OF 100 PCS" },
        { id: 31, GENERIC_NAME: "PLASTIC GLOVES ELBOW LENGTH", STRENGTH: "PKT OF 100 PCS" },
        { id: 32, GENERIC_NAME: "STERILE PLASTIC GLOVES IND. PACK- 1 PAIR", STRENGTH: "BOX OF 100 PCS" },
        { id: 33, GENERIC_NAME: "ALCOHOL SWABS", STRENGTH: "BOX OF 100 PCS" },
        { id: 34, GENERIC_NAME: "BED BATH TOWEL", STRENGTH: "PKT OF 10 PCS" },
        { id: 35, GENERIC_NAME: "LANCET STEEL", STRENGTH: "BOX OF 200 PCS" },
        { id: 36, GENERIC_NAME: "NEEDLE LANCET - PLASTICS", STRENGTH: "BOX OF 100 PCS" },
      
]
export default data;